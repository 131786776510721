import { render, staticRenderFns } from "./BuildWords.vue?vue&type=template&id=7825b972&scoped=true&"
import script from "./BuildWords.vue?vue&type=script&lang=js&"
export * from "./BuildWords.vue?vue&type=script&lang=js&"
import style0 from "./BuildWords.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./BuildWords.vue?vue&type=style&index=1&id=7825b972&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7825b972",
  null
  
)

export default component.exports