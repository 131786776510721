<template>
  <div id="positioner">
    <ActivityHeader activity-type="Cursive Writing"
    :back-button-title="backButtonTitle"
    :back-button-route="backButtonRoute"
    :show-nav-buttons="showNavButtons" />

    <div id="cursiveWrapper">
      <div id="panelWrapper">
        <div id="cursiveTabs">
          <div class="cursiveTab button" :class="{ activeTab: showIntroTab }" @click="switchTabs('showIntroTab')">
            <label>Introduction</label>
          </div>
          <div class="cursiveTab button" :class="{ activeTab: showLowercaseTab }" @click="switchTabs('showLowercaseTab')">
            <label>Lowercase</label>
          </div>
          <div class="cursiveTab button" :class="{ activeTab: showUppercaseTab }" @click="switchTabs('showUppercaseTab')">
            <label>Uppercase</label>
          </div>
        </div>

        <div id="demoContainer">
          <div class="leftPanel">
            <section id="introContainer" v-show="showIntroTab">
              <p>
                Select the above tabs to view upper- and lowercase letter demonstrations. Use these explicit verbalizations when directing
                your students in proper cursive letter formation.
              </p>
              <p>
                Select a video below to view letter formation procedures using the
                <strong>Wilson<sup>&reg;</sup> Writing Grid</strong>, skywriting or <strong>dry erase boards</strong> in a classroom setting.
              </p>
              <ul id="cursiveVideoToggles">
                <li class="button cursiveVideoToggle" @click="toggleCursiveVideo('cursive-introduce', $event)">Introduce Letter</li>
                <li class="button cursiveVideoToggle" @click="toggleCursiveVideo('cursive-skywrite', $event)">Skywrite Letter</li>
                <li class="button cursiveVideoToggle" @click="toggleCursiveVideo('cursive-dryerase', $event)">Dry Erase</li>
              </ul>
            </section>
            <section id="lowercaseLetterList" v-show="showLowercaseTab">
              <h1>Lowercase</h1>
              <span class="cursive-subheading">Cursive Letter Formation</span>

              <p>Click a letter to view its formation</p>
              <ul class="letterList">
                <li
                  v-for="letter in lowerAlphabet.split('')"
                  :key="letter"
                  class="letterToggle cursiveVideoToggle"
                  @click="toggleCursiveVideo(letter, $event)"
                >
                  {{ letter }}
                </li>
              </ul>
            </section>
            <section id="uppercaseLetterList" v-show="showUppercaseTab">
              <h1>Uppercase</h1>
              <span class="cursive-subheading">Cursive Letter Formation</span>

              <p>Click a letter to view its formation</p>
              <ul class="letterList">
                <li
                  v-for="letter in upperAlphabet.split('')"
                  :key="letter"
                  class="letterToggle cursiveVideoToggle"
                  @click="toggleCursiveVideo(letter, $event)"
                >
                  {{ letter }}
                </li>
              </ul>
            </section>
          </div>
          <div class="rightPanel videoTarget" v-show="!showBaseGrid">
            <video autoplay :src="cursiveVideo"></video>
          </div>
          <svg
            version="1.1"
            id="cursiveGrid"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="864px"
            height="648px"
            viewBox="0 0 864 648"
            enable-background="new 0 0 864 648"
            xml:space="preserve"
            v-show="showBaseGrid"
          >
            <g id="WritingGrid">
              <g id="wormline-graphic">
                <path
                  fill="#2AACE3"
                  stroke="#29AAE2"
                  stroke-width="0.5"
                  d="M90.825,526.441c-1.534-0.244-2.775-0.087-3.768,0.481
                        c-0.983,0.568-1.555,1.601-1.717,3.113c0.281,1.662,1.209,2.816,2.775,3.479c1.576,0.657,3.174,0.499,4.804-0.471
                        c1.63-0.98,2.073-2.257,1.317-3.849C93.491,527.613,92.347,526.695,90.825,526.441 M78.808,527.186l-0.508-0.255
                        c-0.01-0.034-0.248-0.14-0.712-0.314c-2.624-0.507-4.427-0.018-5.431,1.47c-0.993,1.478-1.144,2.772-0.442,3.856
                        c0.701,1.085,1.5,1.706,2.396,1.872c1.21,0.367,2.505,0.306,3.898-0.176c1.933-0.9,2.721-2.335,2.343-4.293
                        C80.189,528.453,79.67,527.728,78.808,527.186 M63.713,534.304c-0.237,1.819,0.152,3.367,1.156,4.635
                        c1.025,1.285,2.278,2.423,3.746,3.41c1.468,0.989,2.829,2.056,4.092,3.21c0.82,0.744,1.36,1.601,1.63,2.571
                        c-1.133-1.758-2.742-3.191-4.804-4.303c-0.756-0.411-1.479-0.856-2.17-1.347c-1.566-0.342-2.462-0.445-2.699-0.297
                        c-1.145,1.399-1.674,2.947-1.599,4.644c0.011,0.533-0.01,1.05-0.097,1.548c-0.647-1.645-0.539-3.288,0.314-4.95
                        c0.269-0.516,0.593-1.005,0.971-1.452c0.356-0.472,0.14-0.856-0.648-1.136c-1.781-0.192-3.821-0.35-6.121-0.481
                        c-2.289-0.132-3.812-0.279-4.578-0.438c-0.756-0.166-1.684-0.061-2.764,0.306c-1.09,0.368-2.127,0.832-3.109,1.4
                        c-0.972,0.551-1.749,1.224-2.322,2.011c-0.561,0.787-0.788,1.636-0.68,2.554c0.108,0.909,0.551,1.609,1.318,2.1
                        c3.876,1.154,7.752,2.134,11.649,2.921c3.887,0.778,6.359,1.04,7.417,0.77c0.291-0.149,0.411-0.263,0.356-0.342l-0.896-0.41
                        c-0.388-0.438-0.615-1.006-0.68-1.706l1.091,0.997c0.151,0,0.41,0.026,0.755,0.079l6.187,0.734
                        c-1.533-1.32-2.408-2.877-2.602-4.67c-0.033-0.28-0.044-0.568-0.022-0.857l0.097-0.078l0.173-0.026
                        c0.227,2.229,1.242,4.146,3.045,5.736c3.865,0.639,7.763,0.805,11.66,0.49c1.231-0.097,2.375-0.394,3.455-0.884
                        c2.051-0.944,3.001-2.36,2.872-4.241c-0.033-0.42-0.173-0.805-0.41-1.163c-0.853-1.242-1.814-2.457-2.851-3.629l-0.475-0.018
                        c-0.13,1.819-0.961,3.008-2.483,3.576c-1.512,0.56-3.12,0.612-4.826,0.167c-2.591-0.979-4.319-2.056-5.182-3.228
                        c-0.875-1.172-1.307-1.862-1.296-2.063c-2.073-0.805-3.282-1.68-3.649-2.615l0.205,0.026c0.572,0.743,1.371,1.269,2.408,1.574
                        c0.604,0.175,1.133,0.446,1.576,0.822l0.032-0.289c0.756,1.539,1.955,2.799,3.606,3.778c1.35,0.805,2.851,1.25,4.502,1.338
                        c1.469,0.026,2.645-0.438,3.531-1.408c0.302-0.323,0.55-0.664,0.777-1.032c0.13-0.218,0.249-0.437,0.356-0.665l-1.392-0.156
                        l-0.421,0.821c-0.842,1.138-1.965,1.626-3.369,1.47c-0.529-0.088-1.026-0.271-1.468-0.551c-2.548-1.618-4.545-3.604-5.96-5.965
                        c-0.259-0.42-0.583-1.312-0.993-2.667l0.108-0.009c1.339,1.977,3.239,3.507,5.701,4.583c1.997,0.874,4.124,1.364,6.391,1.469
                        c0.778,0.044,1.63,0.062,2.537,0.053c1.825,0,3.045-0.097,3.66-0.28c1.209-0.367,1.965-1.066,2.246-2.1
                        c0.324-1.171,0.064-2.544-0.767-4.109c-1.824,0.306-3.25,0.263-4.275-0.123c-1.037-0.384-1.825-0.936-2.365-1.645
                        c-0.539-0.716-0.874-1.381-0.993-1.984c-1.026-0.604-1.997-0.489-2.915,0.332c-0.443,1.549-1.522,2.686-3.239,3.42
                        c-1.069,0.464-2.202,0.577-3.368,0.341l-0.41-0.078c-1.026-0.158-1.955-0.612-2.775-1.355c-0.562-0.517-0.918-1.12-1.069-1.802
                        c-1.987-0.734-3.39-1.111-4.232-1.111C64.848,531.217,63.961,532.476,63.713,534.304 M62.688,527.089
                        c-0.54-0.113-1.166-0.236-1.89-0.385l-0.086,1.862c1.123,0.176,2.256,0.368,3.401,0.568c2.235,0.395,4.297,0.98,6.197,1.732
                        l0.054-1.242l-2.742-1.225C65.441,527.596,63.8,527.159,62.688,527.089 M32.878,537.723c-1.77-0.594-2.872-1.276-3.293-2.028
                        c-1.608,0.639-3.228,0.665-4.858,0.079c-0.097-0.035-0.194-0.07-0.302-0.105c0.356,1.881,1.598,3.253,3.746,4.11
                        c2.721,1.093,5.604,1.671,8.638,1.714c1.295,0.018,2.569-0.009,3.854-0.087c0.291-1.574,1.274-2.51,2.958-2.816
                        c1.684-0.297,3.26-0.358,4.729-0.175l4.934-0.122c-3.023,0.498-5.161,0.681-6.424,0.542c-1.997,0.009-3.466,0.515-4.405,1.504
                        c-0.95,0.997-1.123,2.177-0.529,3.568c0.227,0.533,0.583,1.006,1.079,1.426c0.346-2.038,1.577-3.63,3.715-4.775
                        c0.583-0.314,1.198-0.577,1.867-0.787l-0.496-0.306c0.583-0.026,1.069,0.009,1.479,0.104l1.155-0.306
                        c1.415-0.228,3.563-0.105,6.467,0.385l4.513,0.271c-2.969-1.53-6.024-2.344-9.187-2.431c-3.153-0.098-6.781,0.069-10.851,0.498
                        C37.586,538.414,34.661,538.327,32.878,537.723 M72.449,540.47c0.032,0.034,0.065,0.069,0.097,0.122l0.14-0.018
                        C72.61,540.539,72.523,540.504,72.449,540.47 M85.145,529.957c-0.01,0.035-0.01,0.062-0.01,0.088
                        C85.2,530.893,85.2,530.867,85.145,529.957 M84.691,528.165l-0.864-0.185c-1.09-0.35-2.213-0.577-3.357-0.664
                        c0.323,0.332,0.583,0.708,0.766,1.119c0.195,0.411,0.27,0.699,0.227,0.884c0.162-0.315,0.324-0.508,0.507-0.604
                        c0.832-0.411,1.652-0.358,2.473,0.174l0.281-0.655L84.691,528.165z M70.612,528.952c0.141-0.49,0.324-0.857,0.54-1.111
                        c0.227-0.245,0.421-0.48,0.605-0.69c-1.177,0.219-2.116,0.533-2.818,0.936L70.612,528.952z M82.175,529.406
                        c-0.453,0.446-0.68,0.743-0.68,0.9c0.907-0.62,1.846-0.69,2.818-0.191l0.043-0.787C83.363,528.935,82.641,528.961,82.175,529.406
                            M27.622,531.077l0.097-0.079l0.237,0.026c0.011,0.157,0.054,0.323,0.119,0.48c0.432,1.059,1.317,1.618,2.645,1.68
                        c0.605-0.341,1.263-0.63,1.965-0.866c0.292-0.104,1.544-0.271,3.747-0.498c2.191-0.228,3.346-0.7,3.465-1.426
                        c0.529-1.46-0.324-2.501-2.548-3.104c-2.213-0.604-4.901-0.236-8.043,1.119c-3.142,1.354-4.783,3.489-4.934,6.437
                        c1.576,0.979,3.293,1.138,5.139,0.455c0.011-0.647,0.291-1.207,0.821-1.662C28.506,533.377,27.6,532.528,27.622,531.077
                            M26.812,528.619c1.036-0.708,2.191-1.25,3.476-1.609c1.372-0.411,2.796-0.646,4.254-0.734c2.16-0.245,3.898,0.079,5.215,0.971
                        c1.317,0.901,1.582,1.947,1.204,3.023c-0.388,1.075-1.01,1.743-2.295,2.128c-1.274,0.376-2.645,0.612-4.113,0.682
                        c-1.469,0.079-2.613,0.42-3.433,1.032c-0.087,0.105-0.173,0.271-0.27,0.49c-1.042,1.515,2.31,2.212,3.606,2.439
                        c2.861,0.245,5.252,0.402,9.505-0.079c4.265-0.472,8.278-0.698,11.539-0.077c3.271,0.629,6.681,1.756,8.376,3.349l2.162,1.345
                        c-2.769-2.077-3.788-4.166-3.615-6.309c0.13-1.513,0.538-3.923,2.231-5c-1.933-0.464-3.963-0.916-4.956-1.135
                        c-0.065-0.419-0.075-0.944-0.043-1.556c0.043-0.612,0.227-1.19,0.583-1.724c2.623,0.141,5.161,0.727,7.579,1.758
                        c1.577-0.865,3.261-1.294,5.075-1.312c0.528-0.306,1.381-0.551,2.558-0.743c1.188-0.184,2.473,0.07,3.855,0.77
                        c1.997,0.07,3.897,0.437,5.722,1.11c0.486-0.516,1.004-0.936,1.555-1.25c0.561-0.307,1.414-0.525,2.547-0.656
                        c1.145-0.122,2.332,0.104,3.563,0.673c1.231,0.578,2.052,1.434,2.483,2.58c0.422,1.138,0.432,2.178,0.033,3.113
                        c-0.399,0.937-1.177,1.706-2.321,2.31c0.065,0.078,0.129,0.157,0.173,0.235c0.842,1.382,1.747,4.365-0.33,6.365
                        c-1.003,0.682-2.802,1.401-4.842,1.174c0.216,0.21,0.443,0.402,0.648,0.595c1.656,2.001,3.194,3.386,2.579,6.232
                        c-0.162,1.224-1.507,2.113-3.462,3.153c-1.943,1.032-5.342,1.34-10.384,1.077c-4.805-0.253-8.769-1.23-11.384-1.462
                        c-0.044,0.061-0.108,0.531-0.281,0.785c-0.173,0.262-0.551,0.48-1.134,0.664c-0.594,0.192-2.181,0.123-4.783-0.201
                        c-2.591-0.332-14.626-3.485-15.033-3.786c-1.462-1.077-1.453-1.863-1.55-2.379c-0.065-0.035-0.13-0.07-0.194-0.114
                        c-0.055-0.043-0.109-0.087-0.152-0.131c-0.021-0.027-0.043-0.044-0.054-0.053c-0.011-0.009-0.129-0.087-0.356-0.236
                        c-0.227-0.148-0.605-0.576-1.144-1.276c-0.53-0.708-0.756-1.548-0.691-2.527c-0.756-0.104-1.523-0.131-2.278-0.078
                        c-1.911,0.087-3.801,0.017-5.669-0.22c-1.943-0.219-3.272-0.678-4.989-1.447c-1.803-0.814-3.411-2.042-3.81-3.642
                        c-0.41-1.574-0.346-3.148,0.184-4.731C24.458,530.771,25.408,529.572,26.812,528.619"
                ></path>
                <path
                  fill="#2AACE3"
                  d="M74.122,529.302c0,0.044,0.01,0.096,0.032,0.14c0.097,0.279,0.346,0.429,0.756,0.438
                        c0.702-0.324,1.004-0.805,0.918-1.46c-0.065-0.036-0.281-0.053-0.67-0.07C74.78,528.331,74.424,528.646,74.122,529.302
                            M73.582,529.397c0.14-0.997,0.885-1.504,2.235-1.513c1.565,0.464,2.224,1.461,1.976,2.974c-0.476,1.163-1.339,1.46-2.57,0.9
                        C73.981,531.19,73.441,530.403,73.582,529.397"
                ></path>
                <path
                  fill="#2AACE3"
                  d="M88.902,528.199c-0.562,0.132-0.907,0.429-1.047,0.893c0.107,0.595,0.485,0.69,1.122,0.288
                        c0.238-0.157,0.346-0.358,0.335-0.612c-0.011-0.078-0.021-0.157-0.032-0.227L88.902,528.199z M87.521,530.657
                        c-0.724-1.286-0.324-2.274,1.188-2.939c1.252-0.096,2.083,0.395,2.504,1.487c0.41,1.094,0.086,1.932-0.993,2.519
                        C89.14,532.301,88.244,531.951,87.521,530.657"
                ></path>
              </g>
              <line id="wormline" fill="none" stroke="#2AACE3" stroke-width="3" x1="117.511" y1="540.179" x2="828.486" y2="540.179"></line>
              <g id="grassline-graphic">
                <path
                  fill="#2AACE3"
                  stroke="#29AAE2"
                  stroke-width="0.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M32.98,354.069
                        c0.45-0.202,0.915-0.213,1.408-0.04c1.772,0.659,2.658,1.694,2.643,3.104l0.218,0.052l0.174-0.052
                        c1.089-1.268,2.759-1.603,4.995-1.004c2.323,0.629,2.759,1.694,1.293,3.206c-0.93,0.964-2.207,1.481-3.848,1.573
                        c2.163,0.669,3.122,1.785,2.846,3.378c-0.189,1.127-1.046,1.847-2.585,2.161c-1.597,0.325-2.773,0.011-3.499-0.983
                        c-0.276-0.366-0.712-0.58-1.307-0.62c-0.276,1.319-1.234,2.324-2.875,2.994c-1.351,0.547-2.483,0.446-3.412-0.314
                        c-1.017-0.812-1.38-1.766-1.133-2.831c0.305-1.238,1.263-2.171,2.889-2.79l0.102-0.203c-0.436-0.203-0.915-0.203-1.452-0.021
                        c-0.392,0.144-0.712,0.336-0.988,0.56c-2.323-0.011-3.673-0.822-4.036-2.425c-0.261-1.116,0.407-1.948,1.974-2.496
                        c1.874-0.649,3.602-0.457,5.199,0.578l0.247-0.081l0.073-0.306C30.671,356.059,31.034,354.901,32.98,354.069 M69.194,364.154
                        l-0.174,0.051c0.261,1.451,0.421,2.912,0.465,4.363c0.043,0.842-0.087,1.684-0.378,2.506c-0.276-1.41-0.784-2.78-1.525-4.099
                        c-0.392-0.681-0.871-1.341-1.451-1.969c-0.727-0.802-1.598-1.542-2.6-2.191l-0.145,0.021l-0.189,0.202
                        c0.726,2.101,1.176,4.231,1.365,6.372c0.072,0.923-0.073,1.836-0.435,2.749c-0.567,1.38-1.511,2.628-2.788,3.744
                        c-0.131,0.132-0.276,0.254-0.421,0.376c0.362-1.197,0.421-2.405,0.203-3.623c-0.276-1.45-0.756-2.86-1.438-4.251
                        c-0.885-1.775-2.192-3.368-3.949-4.758c-0.479-0.396-1.031-0.741-1.641-1.035l-0.218,0.051l-0.102,0.224
                        c0.654,0.669,1.089,1.42,1.278,2.242c0.349,1.431,0.581,2.88,0.726,4.332c0.146,1.532-0.029,3.054-0.522,4.556
                        c-0.087,0.233-0.16,0.467-0.247,0.689c-1.104-0.863-2.004-1.836-2.701-2.901c-0.552-0.862-0.857-1.766-0.915-2.709
                        c-0.044-0.873-0.305-1.704-0.77-2.506c-0.087-0.152-0.188-0.284-0.304-0.427l-0.334,0.122c0.174,0.608,0.101,1.207-0.233,1.805
                        c-0.784,1.442-1.263,2.943-1.409,4.496c-0.13,1.339-0.014,2.678,0.364,4.007c-1.191-1.045-1.99-2.222-2.382-3.521
                        c-0.45-1.461-0.479-2.922-0.131-4.393c0.102-0.426,0.218-0.853,0.349-1.278l-0.29-0.122l-0.262,0.071
                        c-0.667,0.577-1.277,1.197-1.815,1.897c-0.755,0.994-1.379,2.029-1.844,3.125c-0.741,1.755-0.769,3.521-0.058,5.285l-2.41-0.933
                        c0.217-1.878,0.072-3.744-0.436-5.581c-0.349-1.247-0.929-2.435-1.728-3.57c-0.16-0.244-0.319-0.467-0.493-0.701
                        c0.755,0.325,1.568,0.467,2.453,0.447c0.683-0.031,1.322-0.162,1.931-0.406c0.858-0.345,1.482-0.842,1.874-1.471
                        c0.813-1.299,0.624-2.526-0.552-3.685c-0.32-0.303-0.697-0.557-1.147-0.739c1.118-0.193,2.047-0.578,2.817-1.167
                        c0.508-0.387,0.813-0.832,0.944-1.35c0.159-0.68-0.087-1.288-0.741-1.806c-1.133-0.893-2.555-1.359-4.298-1.39
                        c-0.552-0.011-1.089,0.04-1.626,0.152c-0.421,0.09-0.799,0.243-1.133,0.477c-0.101-0.67-0.465-1.259-1.045-1.775
                        c-0.552-0.478-1.235-0.771-2.062-0.903c-1.046-0.152-1.975,0-2.788,0.477c-1.249,0.751-1.714,1.685-1.365,2.811
                        c-1.06-0.324-2.149-0.396-3.252-0.203c-0.857,0.152-1.598,0.437-2.252,0.853c-0.754,0.478-1.204,1.064-1.379,1.766
                        c-0.29,1.167,0.16,2.182,1.336,3.054c0.421,0.294,0.886,0.518,1.438,0.67c0.435,0.122,0.885,0.172,1.364,0.172
                        c-0.827,0.924-1.19,1.928-1.103,3.004c0.029,0.527,0.218,1.034,0.537,1.512c0.407,0.598,1.06,1.014,1.946,1.248
                        c1.495,0.405,2.933,0.304,4.327-0.305c0.494-0.213,0.93-0.477,1.336-0.781c0.61-0.457,1.031-0.994,1.22-1.604
                        c0.871,1.543,1.597,3.105,2.207,4.707c0.537,1.452,0.842,2.934,0.9,4.435c0.029,0.416,0.029,0.842,0.029,1.269
                        c-1.292-0.802-2.28-1.746-2.948-2.852c-0.638-1.045-0.958-2.13-0.972-3.257c0-0.172,0-0.334,0.014-0.497l-0.45-0.07
                        c-0.857,0.791-1.51,1.663-1.975,2.607c-0.595,1.228-0.973,2.496-1.147,3.784c-0.189,1.35,0.116,2.628,0.929,3.855
                        c0.145,0.233,0.509,0.557,1.104,0.983c-0.479-0.983-0.813-1.988-0.987-3.013c-0.276-1.461-0.175-2.913,0.304-4.353
                        c0.305-0.923,0.741-1.816,1.336-2.679c-0.014,1.177,0.334,2.282,1.06,3.338c0.668,0.954,1.554,1.775,2.701,2.476
                        c0.32,0.193,0.654,0.396,0.987,0.579l0.422,0.264l0.232,0.03c0.857,0.396,2.076,0.7,3.63,0.923
                        c-0.494-1.248-0.697-2.354-0.595-3.327c0.16-1.441,0.566-2.841,1.234-4.19c0.406-0.822,0.886-1.624,1.452-2.405
                        c-0.523,1.461-0.595,2.932-0.218,4.414c0.218,0.872,0.639,1.704,1.249,2.485c0.653,0.832,1.467,1.562,2.454,2.202l0.32,0.03
                        c0.014-2.191,0.101-4.17,0.29-5.936c0.102-1.024,0.334-2.029,0.682-3.013c0.204,1.228,0.639,2.413,1.293,3.571
                        c0.479,0.812,1.074,1.582,1.829,2.323c0.712,0.71,1.685,1.136,2.919,1.268l0.101-0.051l-0.725-0.608
                        c0.508-0.994,0.842-2.019,0.987-3.054c0.218-1.452,0.189-2.892-0.087-4.332c-0.218-1.188-0.581-2.354-1.046-3.501
                        c1.133,1.167,2.062,2.395,2.774,3.713c0.639,1.167,1.132,2.375,1.495,3.612c0.45,1.543,0.523,3.095,0.218,4.657
                        c-0.058,0.273-0.116,0.558-0.174,0.842l-0.102,0.437c0.871-0.243,1.525-0.508,1.961-0.791c0.726-0.467,1.306-1.006,1.786-1.604
                        c0.784-1.004,1.321-2.09,1.582-3.227c0.61-2.516,0.422-5.012-0.537-7.497c1.147,1.217,2.033,2.496,2.686,3.864
                        c0.552,1.127,0.915,2.293,1.104,3.481c0.116,0.781,0.16,1.562,0.102,2.323l0.377,0.11c0.494-0.416,0.842-0.973,1.031-1.673
                        c0.189-0.701,0.377-1.583,0.566-2.648s0.175-2.191-0.043-3.378c0.944,1.359,1.67,2.779,2.192,4.261
                        c0.494,1.42,0.697,2.871,0.625,4.322c-0.044,0.883-0.218,1.744-0.509,2.576c1.394-0.263,2.28-0.842,2.658-1.714
                        c0.377-0.883,0.639-1.775,0.769-2.688c0.131-0.812,0.088-1.634-0.087-2.455c0.901,1.998,2.135,3.916,3.674,5.731h0.029
                        c0.087-3.013,0.508-5.61,1.292-7.781c0.262,2.191,0.842,4.332,1.757,6.442c0.436,1.025,1.191,2.263,2.237,3.693l0.871,0.203
                        l0.217-0.274l-0.246-0.294c1.481-0.68,2.701-1.553,3.63-2.607c0.537-0.599,0.958-1.218,1.278-1.877
                        c0.551-1.126,0.813-2.293,0.798-3.49c0-0.365-0.014-0.73-0.044-1.086c1.119,1.38,1.932,2.852,2.411,4.403
                        c0.494,1.562,0.668,3.155,0.523,4.759c-0.044,0.477-0.087,0.953-0.16,1.44l0.697-0.366c0.494-1.236,0.653-2.505,0.509-3.804
                        c-0.161-1.32-0.567-2.607-1.235-3.865c-0.915-1.735-2.091-3.358-3.514-4.86l-0.436,0.152c0.32,1.502,0.334,3.002,0.059,4.504
                        c-0.146,0.782-0.436,1.533-0.872,2.252c-0.915,1.543-2.279,2.862-4.066,3.969c-0.74-0.975-1.277-1.999-1.611-3.074
                        c-0.741-2.323-1.511-4.648-2.324-6.96c-0.101-0.295-0.203-0.589-0.304-0.883c-1.322,2.161-1.975,4.688-1.961,7.579
                        c-0.9-1.989-1.974-3.928-3.252-5.804c-0.494-0.721-1.031-1.411-1.655-2.091l-0.291,0.103c0.624,1.856,0.915,3.723,0.886,5.6
                        c-0.03,1.015-0.262,2.009-0.712,2.973c-0.058,0.143-0.131,0.274-0.189,0.416c0.175-1.612,0.015-3.226-0.508-4.799
                        c-0.58-1.806-1.481-3.541-2.671-5.185c-0.407-0.578-0.814-1.146-1.235-1.725L69.194,364.154z"
                ></path>
                <path
                  fill="#2AACE3"
                  stroke="#29AAE2"
                  d="M35.682,361.861c-1.336,0.224-2.237,0.051-2.716-0.518
                        c-0.421-0.862-0.145-1.593,0.799-2.211c2.178-0.365,3.122,0.253,2.817,1.846C36.509,361.364,36.204,361.658,35.682,361.861
                            M33.91,358.503l-0.407,0.173c-1.582,0.73-2.004,1.786-1.234,3.176c0.77,0.689,1.975,0.893,3.674,0.608
                        c0.857-0.243,1.336-0.71,1.467-1.399C37.744,359.122,36.582,358.271,33.91,358.503"
                ></path>
              </g>
              <line id="grassline" fill="none" stroke="#2AACE3" stroke-width="3" x1="117.511" y1="376.607" x2="828.486" y2="376.607"></line>
              <g id="planeline-graphic">
                <path
                  fill="#2AACE3"
                  d="M32.531,203.845c-0.347-0.357-1.335-1.083-1.85-1.157c-0.758-0.121-3.585-0.308-4.317-0.231
                        c-0.2,0.009-0.589,0.205-0.579,0.425l0.238,0.531l2.787,0.008l0.252,0.501l-2.814,0.039c0,0,2.673,5.329,2.83,5.63l2.219-0.041
                        c0.116,0.27-1.156,0.71-1.966,0.694l0.81,1.58c0,0-3.954,5.011-4.395,5.474l-0.243,0.3c1.589,0.122,2.108,0.118,3.685,0.15
                        c0.411,0.025,0.821-0.016,1.242-0.121c2.197-0.585,4.28-1.348,6.226-2.289c0.021-0.009,0.042-0.009,0.063-0.009
                        c0-0.016,0.01-0.016,0.021-0.032v-0.008c0.178-0.09,0.368-0.179,0.546-0.276c0.053-0.017,0.106-0.033,0.158-0.049
                        c0-0.008,0-0.008,0.011-0.016c0.042-0.008,0.063-0.024,0.094-0.033c0.032-0.016,0.053-0.032,0.074-0.049
                        c0.042-0.008,0.095-0.024,0.137-0.04c0.021-0.008,0.052-0.024,0.063-0.041c0.252-0.081,0.494-0.186,0.726-0.308
                        c0,0,0.01-0.008,0.021-0.016c0.01,0,0.031-0.009,0.052-0.009c0.032-0.007,0.063-0.016,0.095-0.024c0-0.008,0.01-0.024,0.021-0.032
                        l0.136,0.089l-0.063,0.089l7.383,0.122l3.366-2.394c-0.148,0.275-0.263,0.47-0.358,0.584c-0.294,0.341-0.367,0.365-0.714,0.673
                        c1.262,0.016,5.088,0.386,6.091,1.079c-0.316,0.423-11.96,8.72-12.245,8.736c-1.787,0.081-3.575,0.073-5.363-0.016l-2.59,2.228
                        c2.798,0.625,5.808,0.905,8.711,0.848c0.484-0.008,7.061-3.615,9.066-4.618c0.567-0.283,15.059-7.072,16.836-7.794
                        c-5.061-1.808-10.792-2.159-18.19-1.845c3.734-0.763,7.348-1.004,10.776-0.647c1.504,0.154,2.998,0.357,4.481,0.609
                        c1.094,0.187,2.093,0.431,3.008,0.739c1.493,0.446,2.261,0.828,2.324,1.144l-1.628,0.848c14.416,0.617,23.191-0.06,22.356-0.617
                        c-0.127-0.073-0.519-0.047-0.54-1.638c0.021-0.399,0.579-1.388,0.964-1.562c-0.673-0.389-2.043-0.77-3.276-0.809
                        c-1.234,0.385-2.13,0.357-3.624,0.154l-0.791-1.263c1.546-0.081,1.8-0.121,2.179-0.047c-0.61-0.277-2.165-0.439-2.544-0.463
                        c-0.947-0.065-21.349-0.085-24.745-0.077c-4.975,0.016-23.513,0.154-23.513,0.154S32.646,203.999,32.531,203.845 M44.48,200.606
                        l2.389,2.698c0.137-0.032,0.939-0.077,3.084-0.231c0.484,0.309,6.167,4.625,6.167,4.625l10.947-0.153
                        c-0.873-0.228-13.581-5.783-14.686-6.245c-1.094-0.471-2.35-0.733-3.392-0.733C47.959,200.576,44.48,200.606,44.48,200.606
                            M66.499,217.408c-0.084-0.188-0.035-0.049-0.203-0.187c0.231,0.376,0.138,1.201-0.472,1.59c-0.61,0.39-1.192,0.232-1.571-0.125
                        c-0.308-0.308-0.424-0.694-0.231-1.156l-4.125,1.696c1.336,0.04,2.144,0.093,3.47,0.077c1.603-0.02,2.669,0.004,3.153-1.125
                        C66.625,217.918,66.625,217.659,66.499,217.408 M58.148,222.001c0.011-0.333-0.073-0.649-0.241-0.942
                        c-0.074-0.137-0.231-0.332-0.463-0.6c0.463,0.787,0.796,2.042-0.476,2.659c-0.315,0.097-1.002,0.154-1.388-0.308
                        c-0.232-0.28-0.355-0.979-0.324-1.426l-4.122,2.07c0.967,0.106,1.872,0.244,2.734,0.406c0.547,0.105,1.084,0.13,1.609,0.081
                        c0.916-0.081,1.662-0.398,2.219-0.933C57.991,222.716,58.138,222.384,58.148,222.001 M45.486,216.247l-2.545,1.989
                        c-0.978,0.762-1.914,1.542-2.819,2.354c-0.83,0.746-1.49,1.353-2.426,2.124l4.162,0.097c0.558-0.398,11.011-7.746,11.178-7.941
                        c-0.315-0.089-2.796-0.49-5.078-0.645C47.537,214.583,46.717,215.248,45.486,216.247 M57.009,220.998
                        c-0.048-0.063-0.086-0.113-0.115-0.148l-0.962,0.519c-0.017,0.06-0.028,0.153-0.028,0.292c0,0.265,0.068,0.483,0.198,0.655
                        c0.142,0.182,0.29,0.257,0.458,0.257c0.169,0,0.319-0.078,0.449-0.257c0.138-0.171,0.208-0.39,0.208-0.655
                        C57.217,221.396,57.149,221.178,57.009,220.998 M64.633,217.494c-0.005,0.008-0.011,0.023-0.018,0.049
                        c-0.014,0.051-0.024,0.13-0.024,0.238c0,0.098,0.022,0.163,0.056,0.222c0.019,0.033,0.041,0.065,0.066,0.096l0.09,0.114
                        c0.105,0.113,0.679,0.161,0.829-0.022c0.193-0.235,0.258-0.442,0.203-0.605c-0.061-0.222-0.132-0.383-0.196-0.474
                        c-0.025-0.038-0.044-0.06-0.057-0.068C65.519,217.076,65.207,217.236,64.633,217.494 M47.486,204.153l2.621,3.161l4.163,0.077
                        c-0.883-0.714-3.273-2.633-4.24-3.315C49.473,204.083,47.539,204.209,47.486,204.153 M31.204,201.862
                        c0.169,0.041,0.347,0.106,0.516,0.179c0.283,0.146,0.526,0.316,0.757,0.519l1.946,2.086c0.757,0.812,1.493,1.649,2.187,2.493
                        c0.094,0.113,0.252,0.3,0.484,0.552c0,0,11.163-0.145,11.394-0.145c-0.631-0.983-4.583-5.657-5.088-6.323
                        c-0.473-0.616-0.07-1.245,0.54-1.31c0.61-0.016,4.173-0.099,4.51-0.115c0.915-0.074,3.3,0.334,4.278,0.732
                        c2.041,0.795,16.34,7.016,16.497,7.016c0.652,0.024,14.539,0.166,15.495,0.231c2.929,0.029,4.703,1.387,4.703,1.387
                        c1.465,0.309,5.897,1.309,6.071,3.393c0.173,2.081-3.334,2.975-4.354,3.186c-0.273,0.049-0.547,0.089-0.851,0.114
                        c-0.989,0.081-2.009,0.13-3.051,0.154c-3.344,0.065-6.878,0.106-10.569,0.114c-2.924,0.016-5.847,0-8.782-0.032l-0.473-0.009
                        l-0.81,0.405c1.031,0.779,1.006,1.83,0.155,2.698c-0.733,0.81-2.716,0.881-3.893,0.848c-1.2-0.041-2.603-0.184-4.286-0.176
                        l-0.456,0.176c0.148,0.041,0.484,0.375,0.694,0.733c0.2,0.389,0.382,0.929,0.309,1.464c-0.095,0.706-0.68,1.576-1.426,1.967
                        c-0.568,0.3-1.409,0.504-2.082,0.578c-1.461,0.158-5.389-0.542-5.82-0.656c-2.156,1.177-3.503,2.005-5.354,3.052l-0.031,0.016
                        c0.126,0.032-0.463,0.13-1.767,0.301c-3.05,0.105-6.047-0.139-9.013-0.723c-0.148-0.024-1.628-0.524-1.026-0.95
                        c2.45-2.151,10.813-9.129,11.949-10.176h-6.399c-2.261,1.29-5.485,2.505-8.171,3.007c-0.621,0.186-6.476-0.155-6.476-0.155
                        c-0.273-0.308,4.475-5.887,5.242-6.861c0,0-4.509-8.442-3.893-9.368c0.548-0.821,2.422-0.759,3.368-0.767
                        C29.227,201.48,30.216,201.602,31.204,201.862"
                ></path>
                <path
                  fill="#2AACE3"
                  stroke="#29AAE2"
                  stroke-miterlimit="3.864"
                  d="M76.249,209.623c0.105,0.073,0.158,0.162,0.158,0.276
                        c0,0.105-0.053,0.203-0.158,0.276c-0.095,0.081-0.221,0.114-0.358,0.114c-0.147,0-0.273-0.033-0.368-0.114
                        c-0.105-0.073-0.147-0.171-0.147-0.276c0-0.114,0.042-0.203,0.147-0.276c0.095-0.082,0.221-0.122,0.368-0.122
                        C76.027,209.5,76.153,209.541,76.249,209.623"
                ></path>
                <path
                  fill="#2AACE3"
                  stroke="#29AAE2"
                  stroke-miterlimit="3.864"
                  d="M80.308,209.623c0.169,0,0.316,0.032,0.442,0.113
                        c0.126,0.074,0.189,0.163,0.189,0.268c0,0.106-0.063,0.195-0.189,0.268c-0.126,0.073-0.273,0.114-0.442,0.114
                        c-0.179,0-0.336-0.041-0.462-0.114c-0.116-0.073-0.179-0.162-0.179-0.268c0-0.105,0.063-0.194,0.179-0.268
                        C79.972,209.654,80.128,209.623,80.308,209.623"
                ></path>
                <path
                  fill="#2AACE3"
                  stroke="#29AAE2"
                  stroke-miterlimit="3.864"
                  d="M77.941,209.696c0.158,0,0.295,0.032,0.41,0.097
                        c0.106,0.065,0.169,0.146,0.169,0.235c0,0.098-0.063,0.171-0.169,0.244c-0.115,0.065-0.252,0.097-0.41,0.097
                        s-0.294-0.032-0.399-0.097c-0.116-0.073-0.169-0.146-0.169-0.244c0-0.089,0.053-0.17,0.169-0.235
                        C77.647,209.728,77.783,209.696,77.941,209.696"
                ></path>
                <path
                  fill="#2AACE3"
                  stroke="#29AAE2"
                  stroke-miterlimit="3.864"
                  d="M82.085,209.639c0.137-0.065,0.295-0.098,0.495-0.098
                        c0.189,0,0.357,0.033,0.494,0.098c0.136,0.065,0.199,0.146,0.199,0.235c0,0.098-0.063,0.171-0.199,0.244
                        c-0.137,0.065-0.305,0.097-0.494,0.097c-0.2,0-0.358-0.032-0.495-0.097c-0.137-0.073-0.2-0.146-0.2-0.244
                        C81.885,209.785,81.948,209.704,82.085,209.639"
                ></path>
                <path
                  fill="#2AACE3"
                  stroke="#29AAE2"
                  stroke-miterlimit="3.864"
                  d="M28.985,204.076l2.847,5.402c0,0-1.703-1.474-2.269-2.742
                        C29.13,205.767,28.985,204.076,28.985,204.076"
                ></path>
                <path
                  fill="#2AACE3"
                  stroke="#29AAE2"
                  stroke-miterlimit="3.864"
                  d="M33.726,212.711c2.429,0.231,3.932,0.771,4.293,1.214
                        c-2.744-0.658-5.672-0.908-8.417-0.713C29.602,213.212,32.105,212.605,33.726,212.711"
                ></path>
              </g>
              <line
                id="planeline"
                fill="none"
                stroke="#2AACE3"
                stroke-width="3"
                stroke-miterlimit="3.864"
                stroke-dasharray="16 10"
                x1="828.495"
                y1="215.937"
                x2="117.5"
                y2="215.937"
              ></line>
              <path
                id="skyline-graphic"
                fill="#2AACE3"
                d="M64.495,47.983l0.071-0.056l-7.002,1.531c0.499,0.418,0.951,0.871,1.348,1.354
                    C60.399,49.511,62.342,48.543,64.495,47.983 M55.407,48.026l2.002-5.796l-5.606,4.518C53.103,47.021,54.317,47.458,55.407,48.026
                        M49.702,46.459l-2.071-6.315l-2.163,6.593c1.002-0.206,2.055-0.317,3.14-0.317C48.977,46.42,49.342,46.434,49.702,46.459
                        M43.942,47.138l-6.088-4.908l2.347,6.797C41.28,48.23,42.546,47.586,43.942,47.138 M39.263,49.801l-8.566-1.874l6.322,4.969
                    C37.515,51.751,38.283,50.705,39.263,49.801 M36.656,53.926l-8.579,1.783l8.463,1.76c-0.104-0.5-0.158-1.011-0.158-1.532
                    C36.382,55.248,36.476,54.575,36.656,53.926 M36.88,58.633l-6.183,4.86l8.184-1.79C37.99,60.794,37.306,59.757,36.88,58.633
                        M37.606,62.937l-10.873,2.378l8.988-7.065L23.5,55.709l12.221-2.54l-8.988-7.065l12.179,2.666l-3.346-9.696l8.874,7.154
                    l3.191-9.728l3.192,9.728l8.874-7.154l-3.291,9.534c0.058,0.037,0.115,0.074,0.171,0.112l11.953-2.615l-1.888,1.463
                    c0.742-0.095,1.498-0.145,2.259-0.145c7.09,0,12.89,4.11,12.931,9.236c0.988-0.293,1.961-0.448,3.049-0.448
                    c4.072,0,7.447,2.313,7.845,5.3c1.72,1.097,2.773,2.486,2.773,4.018c0,3.717-6.132,6.582-13.404,6.582
                    c-3.613,0-7.239-0.665-10.225-2.325c-2.112,1.322-5.856,2.159-9.96,2.159c-3.161,0-7.021-0.494-9.735-2.035
                    c-1.992,2.777-6.775,3.642-10.827,1.999c-4.16-1.687-6.085-5.425-4.199-8.372C37.284,63.325,37.436,63.126,37.606,62.937
                        M53.026,55.789c1.045,0,1.985,0.102,2.968,0.301c0.204-1.75,0.989-3.29,2.184-4.568c-1.851-2.625-5.362-4.26-9.57-4.26
                    c-6.155,0-11.144,3.886-11.144,8.676c0,2.04,0.905,3.915,2.419,5.396c1.07-0.48,2.297-0.771,3.529-0.848
                    C44.722,57.746,48.559,55.789,53.026,55.789 M56.206,57.175c-1.017-0.234-1.918-0.363-3.017-0.363
                    c-4.958,0-8.914,2.787-8.914,6.167c0,1.942,1.694,4.176,3.744,5.312l-0.677-0.142c-2.647-1.212-4.409-3.257-4.409-5.593
                    c0-0.561,0.102-1.105,0.291-1.625c-6.643,0.895-7.355,7.691-1.214,10.181c3.357,1.362,7.291,0.764,9.407-1.715
                    c-0.695-0.568-1.23-1.208-1.23-2.065c0-1.661,2.149-3.036,5.166-3.832l0.858-0.087c-2.439,0.779-4.547,2.417-4.547,3.634
                    c0,0.571,0.293,1.029,0.71,1.462c0.06,0.057,0.121,0.113,0.183,0.169l0.086,0.065c0.092,0.067,0.135,0.092,0.212,0.152
                    c2.461,1.714,6.149,2.223,9.245,2.223c3.987,0,7.321-0.866,9.083-2.084c0.273-0.198,0.484-0.373,0.715-0.602
                    c0.414-0.44,0.637-0.905,0.637-1.385c0-0.838-0.673-1.648-1.91-2.333l-0.337-0.338c1.652,0.775,3.344,1.786,3.344,2.955
                    c0,0.546-0.23,1.063-0.65,1.538c2.67,1.591,6.037,2.235,9.331,2.235c6.751,0,12.006-2.757,12.006-5.988
                    c0-1.058-0.556-2.063-1.554-2.941c-0.022,1.393-0.958,2.846-2.4,3.749l0.201-0.342c0.815-0.876,1.295-1.95,1.295-3.112
                    c0-2.932-3.063-5.319-6.854-5.319c-1.272,0-2.23,0.237-3.276,0.656c-0.361,1.851-1.93,3.507-3.578,4.947l0.341-0.417
                    c1.26-1.301,1.964-2.761,2.148-4.391c0.016-0.18,0.029-0.361,0.033-0.543c-0.169-5.066-5.225-8.728-11.564-8.728
                    c-5.884,0-10.795,3.202-11.509,7.854c3.039,1.109,5.517,3.142,5.517,6.025c0,1.639-0.868,3.136-2.3,4.301l-0.57,0.338
                    c1.057-1.016,1.854-2.876,1.854-4.216c0-2.549-2.126-4.485-4.871-5.497C56.894,57.37,56.555,57.267,56.206,57.175"
              ></path>
              <line
                id="skyline"
                fill="none"
                stroke="#2AACE3"
                stroke-width="6"
                stroke-miterlimit="3.864"
                x1="117.5"
                y1="54.5"
                x2="828.434"
                y2="54.5"
              ></line>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <PrevNextButtons activity="CURSIVE"></PrevNextButtons>
  </div>
</template>

<script>
import ActivityHeader from '@/components/ActivityHeader.vue';
import PrevNextButtons from '@/components/PrevNextButtons.vue';

export default {
  name: 'Cursive',
  components: { ActivityHeader, PrevNextButtons },
  data() {
    const upperAlphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowerAlphabet = upperAlphabet.toLowerCase();
    return {
      showIntroTab: true,
      showUppercaseTab: false,
      showLowercaseTab: false,
      showBaseGrid: true,
      cursiveVideo: '',
      upperAlphabet,
      lowerAlphabet,
      showNavButtons: false,
      backButtonRoute: '',
      backButtonTitle: '',
    };
  },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },

  beforeRouteEnter(to, from, next) {

    next((vm) => {
      if (from !== null) {
        if (from.name === 'chooseunit') {
          vm.backButtonTitle = 'Units';
          vm.backButtonRoute = 'chooseunit';
          vm.showNavButtons = false;
        } else {
          vm.backButtonTitle = 'Activities';
          vm.backButtonRoute = 'chooseactivity';
          vm.showNavButtons = true;
        }
      }
    });
  },

  computed: {},
  methods: {
    switchTabs(currentPage) {
      this.resetCursiveLayout();
      this.cursiveVideo = '';
      this.showIntroTab = false;
      this.showUppercaseTab = false;
      this.showLowercaseTab = false;
      switch (currentPage) {
        case 'showIntroTab':
          this.showIntroTab = true;
          break;

        case 'showUppercaseTab':
          this.showUppercaseTab = true;
          this.showBaseGrid = true;
          break;

        case 'showLowercaseTab':
          this.showLowercaseTab = true;

          break;

        default:
          break;
      }
    },

    // show letter animation or intro video when button clicked.
    toggleCursiveVideo(letter, e) {
      this.removeActiveClass();
      e.target.classList.toggle('activeButton');
      this.showBaseGrid = false;
      const regexp = /^[A-Z]/;
      if (regexp.test(letter)) {
        letter += '2';
      }
      this.cursiveVideo = `/videos/${letter}.mp4`;
    },
    removeActiveClass() {
      const letterToggles = document.getElementsByClassName('cursiveVideoToggle');
      for (let i = 0; i < letterToggles.length; i += 1) {
        letterToggles[i].classList.remove('activeButton');
      }
    },
    resetCursiveLayout() {
      this.removeActiveClass();
      this.showBaseGrid = true;
    },
  },
};
</script>

<style scoped lang="scss">
#cursiveWrapper {
  margin: auto;
  display: table;
  max-width: 900px;
  width: 100%;
  margin-top: 30px;
}
#cursiveVideoToggles {
  padding: 0;
  width: 100%;
  max-width: 26rem;
  margin: 1rem auto;
  li {
    margin: 1rem;
  }
}
#cursiveTabs {
  width: 100%;
  float: left;
  border-bottom: solid $lightgray 1px;
}

.cursiveTab {
  width: 200px;
  background-color: white;
  float: left;
  padding: 7px 10px;
  border: solid 1px $lightgray;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin: 5px 5px 0 5px;
  @extend %headings-shared;
}
.cursiveTab.activeTab {
  background-color: $blue;
}
.cursiveTab.activeTab label {
  color: white;
}

.cursiveTab label {
  font-weight: 700;
  color: $blue;
}
.cursive-subheading {
  @extend %headings-shared;
  font-size: 1.4rem;
  margin-bottom: 2rem;
  display: block;
}
.leftPanel,
.rightPanel {
  width: 50%;
  box-sizing: border-box;
  float: left;

  h1 {
    margin: 0;
  }
}
.rightPanel video {
  width: 100%;
}
#introContainer .leftPanel {
  padding-right: 5%;
}

#cursiveGrid {
  width: 50%;
  margin-top: 40px;
  height: auto;
}

.activeButton {
  animation-name: activeButton;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes activeButton {
  0% {
    background-color: #40c16e;
  }

  50% {
    background-color: #64d68d;
  }

  100% {
    background-color: #40c16e;
  }
}

#stepWrapper {
  display: table;
  margin: auto;
}

#stepLabel {
  display: table;
  margin: auto;
  font-size: 30px;
}

#stepNumber {
  display: table;
  margin: auto;
  font-size: 40px;
}

#skyline,
#planeline,
#grassline,
#wormline {
  transform-origin: center;
  transition: transform 150ms ease-in-out;
}
.letterList {
  display: flex;
  padding-left: 0;
  flex-wrap: wrap;
  margin: 20px 15px 0 0;
}
.letterToggle {
  cursor: pointer;
  font-size: 2.6rem;
  line-height: 2rem;
  background: #46b8e7;
  padding: 1.3rem 1.6rem;
  color: white;
  border: none;
  margin: 3px;
  list-style-type: none;
}

#demoContainer {
  padding: 20px;
  float: left;
  width: 100%;
  box-sizing: border-box;
}
#videoContainer video {
  width: 100%;
}
</style>
