<template>
  <div id="app" :class="[currentProgram]" spellcheck="false">
    <div id="app-bg-images" v-if="showBgImages">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <section id="activity-frame" class="has-border">
      <header id="app-header">
        <router-link to="/" aria-label="Interactivities Homepage" id="btnHomePage">
          <div class="logo-container" data-automationId="header-siteTitle">
            <div id="fun-logo-container">
              <img alt="Interactivities Homepage" aria-role="presentation" src="/images/FUN-OWL.svg" />
            </div>
            <div id="ia-logo-container">
              <img alt="Interactivities Homepage" aria-role="presentation" src="/images/IA-LOGO.svg" />
            </div>
          </div>
        </router-link>
      </header>
      <router-view></router-view>
      <button aria-label="toggle fullscreen" id="toggle-fullscreen" class="shell-button" @click="toggleFullscreen"></button>
      <div id="increase-size-overlay">
        <p id="increase-heading">Please <span id="increase-word">increase</span> your screen to use the Interactivities.</p>
        <p id="increase-reason">
          Because of the amount of items included in any given activity (sound cards, phrases, syllable frames, etc...), you'll need to make
          your browser window a bit bigger so that we can provide you with the best experience possible.
        </p>
      </div>
    </section>
    <footer id="app-footer">
      <div id="footer-left-links">
        <a
          href="https://wilsonlanguagetraining.wufoo.com/forms/wilson-digital-products-feedback/"
          id="feedback-button"
          data-automationId="footer-sendFeedback"
          >Send Feedback</a
        >
        <a href="/" id="change-level" data-automationId="footer-changeLevel">Change Level</a>
        <a v-if="isFunHub === 'true'" id="funhub-link" :href="funHubLink">Back to Fun Hub</a>
      </div>
      <nav id="footer-links">
        <a
          href="http://wilsonacademy.com"
          rel="noopener noreferrer"
          target="_blank"
          id="btnWilsonAcademy"
          data-automationId="footer-wilsonAcademy"
          >Wilson Academy</a
        >
        <a
          href="http://wilsonlanguage.com/privacy-policy"
          rel="noopener noreferrer"
          target="_blank"
          id="btnPrivacyPolicy"
          data-automationId="footer-privacyPolicy"
          >Privacy Policy</a
        >
        <a
          href="http://wilsonlanguage.com/terms-of-use"
          rel="noopener noreferrer"
          target="_blank"
          id="btnTermsOfUse"
          data-automationId="footer-termsOfUser"
          >Terms of Use</a
        >
      </nav>
      <p id="footer-copyrights" data-automationId="footer-copyright">{{ programCopyright }} <span class="version">v1.0</span></p>
      <a
        id="logo-wlt-link"
        href="http://wilsonlanguage.com"
        rel="noopener noreferrer"
        target="_blank"
        data-automationId="footer-corpSite"
      ></a>
    </footer>

    <div id="program-side-marker">
      FUN
      <br />
    </div>
    <paintable
      :active="drawingActive"
      :hide="drawingHidden"
      :horizontalNavigation="false"
      :factor="1"
      :lineWidth="lineWidth"
      :navigation="navigation"
      :name="'fundrawing'"
      :lineWidthEraser="15"
      :color="drawingColor"
      ref="paintable"
      :useEraser="useEraser"
      @toggle-paintable="toggledPaintable"
      :class="[drawActive, drawTool]"
    >
    </paintable>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import dragHelper from '@/helpers/checkNativeDrag';
import mixpanel from 'mixpanel-browser';
// import KeyboardEvent from '@/components/keyboardEvent.vue';

export default {
  name: 'App',
  data() {
    const { programId } = this.$route.params;
    const isFullscreen = false;
    const drawingActive = false;
    const drawingHidden = false;
    const drawingColor = '#F00';
    const drawingLinewidth = 5;
    const drawActive = '';
    const useEraser = false;
    const drawTool = 'paint-pencil';
    return { programId, isFullscreen, drawingActive, drawingColor, drawingHidden, drawActive, drawingLinewidth, useEraser, drawTool };
  },
  components: {
    // KeyboardEvent,
  },
  computed: {
    ...mapGetters('authentication', ['isAuthenticated', 'isTokenExpired']),
    ...mapGetters('appState', ['currentProgram']),
    ...mapState('authentication', ['isFunHub']),
    ...mapState('authentication', ['userId', 'userEmail', 'userName']),

    funHubLink() {
      return process.env.VUE_APP_FUNHUB_URL;
    },
    showBgImages() {
      const showImages = this.$route.meta.showBgImages ?? false;
      return showImages;
    },
    lineWidth() {
      if (this.$route.name === 'letterformationgrids') {
        return 20;
      }
      return 5;
    },
    usingEraser() {
      return this.$refs.paintable.useEraser;
    },
    programCopyright() {
      const program = this.$route.params.programId;
      if (program === 'fun2') {
        return '©2004, 2012, 2019 Wilson Language Training. All Rights Reserved';
      }
      if (program === 'fun3') {
        return '©2005, 2012, 2019 Wilson Language Training. All Rights Reserved';
      }
      return '©2019 Wilson Language Training. All Rights Reserved';
    },
    navigation() {
      return {
        'draw-save': {
          body: '',
          activeBody: '<strong>Exit drawing</strong>',
        },
        'eraser-pencil': {
          body: 'use eraser',
          activeBody: 'use pencil',
          click: () => this.toggleTool(),
        },
        cancel: {
          body: '',
          disabled: true,
          click: () => {},
        },
      };
    },
  },
  mounted() {
    const scripts = ['DragDropTouch'];
    scripts.forEach((path) => {
      const scriptTag = document.createElement('script');
      scriptTag.setAttribute('src', `/scripts/${path}.js`);
      document.head.appendChild(scriptTag);
    });

    dragHelper.setNativeDragSupport();
    this.mixPanelTracking();
  },
  created() {
    if (this.programId) {
      this.setCurrentProgram({ program: this.programId });
    } else {
      this.setCurrentProgram({ program: 'fun' });
    }
    if (this.isAuthenticated()) {
      this.registerUserWithMixPanel();
      const fromRoute = this.$route.name;
      const fromParams = this.$route.params;
      this.renewSession().catch((err) => {
        if (err.error === 'login_required') {
          this.logout(); // clear out session junk
          this.login({ name: fromRoute, params: fromParams });
        } else {
          console.error('Error renewing', err);
        }
      });
    }
  },

  methods: {
    ...mapActions('authentication', {
      login: 'login',
      logout: 'logout',
      renewSession: 'renewSession',
    }),
    ...mapActions('appState', ['setCurrentProgram']),

    /* Get the documentElement (<html>) to display the page in fullscreen */

    /* View in fullscreen */
    openFullscreen() {
      this.isFullscreen = true;
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
    },

    toggleDrawing() {
      this.drawingActive = !this.drawingActive;
    },
    toggleHidden() {
      this.drawingHidden = !this.drawingHidden;
    },
    toggledPaintable(isActive) {
      this.drawActive = isActive ? 'drawActive' : '';
      this.useEraser = !isActive;
      if (isActive) {
        this.drawTool = this.useEraser ? 'paint-eraser' : 'paint-pencil';
      } else {
        this.drawTool = '';
      }
      if (this.drawingActive !== isActive) {
        this.drawingActive = isActive;
        mixpanel.track(`Drawing ${!this.drawingActive ? 'Dea' : 'A'}ctivated`);
      }
    },
    toggleTool() {
      this.useEraser = !this.useEraser;
      if (this.drawingActive) {
        this.drawTool = this.useEraser ? 'paint-eraser' : 'paint-pencil';
      } else {
        this.drawTool = '';
      }
    },

    /* Close fullscreen */
    closeFullscreen() {
      this.isFullscreen = false;
      if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement) {
        // can use exitFullscreen

        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          document.msExitFullscreen();
        }
      }
      const evt = new KeyboardEvent('keydown', { keyCode: 27, which: 27 });
      document.dispatchEvent(evt);
    },

    toggleFullscreen() {
      if (!this.isFullscreen) {
        mixpanel.track('Fullscreen Mode Activated');
        this.openFullscreen();
      } else {
        mixpanel.track('Fullscreen Mode Deactivated');
        this.closeFullscreen();
      }
    },
    toggleCursor() {
      document.body.classList.toggle('hideCursor');
    },
    registerUserWithMixPanel() {
      if (this.isFunHub === 'true') {
        const uri = window.location.search.substring(1);
        const params = new URLSearchParams(uri);
        this.registerUnauthedUserWithMixPanel(params.get('email'), params.get('name'));
      } else {
        this.registerAuthedUserWithMixPanel();
      }
    },
    registerAuthedUserWithMixPanel() {
      const tokenJson = sessionStorage.getItem('idToken_payload');
      if (tokenJson) {
        const token = JSON.parse(tokenJson);
        const user = {
          id: token['https://wilsonacademy.com/contactId'],
          email: token['https://wilsonacademy.com/email'],
          name: token['https://interactivities.wilsonacademy.com/name'],
          roles: token['https://wilsonacademy.com/roles'],
        };
        mixpanel.identify(user.email);
        mixpanel.people.set({
          $name: user.name,
          $email: user.email,
          $distinct_id: user.email,
          roles: user.roles,
          'Wilson ID': user.id,
        });

        this.finalizeMixpanelRegistration();
      }
    },
    registerUnauthedUserWithMixPanel(email, name) {
      if (email && name) {
        mixpanel.identify(email);
        mixpanel.people.set({
          $name: name,
          $email: email,
          $distinct_id: email,
        });
        this.finalizeMixpanelRegistration();
      }
    },
    finalizeMixpanelRegistration(email) {
      // do not track test users
      if (email === 'uitester@wilsonlanguage.com') {
        mixpanel.opt_out_tracking();
      }

      mixpanel.track('Arrived On Site');
    },
    mixPanelTracking() {
      mixpanel.track_links('#btnTermsOfUse', 'Go to Terms of Use');
      mixpanel.track_links('#btnPrivacyPolicy', 'Go to Privacy Policy');
      mixpanel.track_links('#btnWilsonAcademy', 'Go to Wilson Academy');
      mixpanel.track_links('#feedback-button', 'Go to Feedback');
      mixpanel.track_links('#btnHomePage', 'Go to Landing Page');
    },
  },
};
</script>
<style lang="scss">
@import '~@/styles/font-imports';
@import '~@/styles/carousel';

.hidePage {
  display: none;
}
.paint-pencil {
  cursor: url('/images/pencil-draw.svg') 1 31, auto;
}
.paint-eraser {
  cursor: url('/images/pencil-eraser.svg') 1 31, auto;
}

.paintable {
  #app & {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: unset !important;

    &.drawActive {
      z-index: 99999;
      .navigation {
        width: auto;
        .navigationMenu {
          background-color: #333;
        }
      }
    }
    &:not(.drawActive) {
      .navigationMenu {
        width: 100%;
        height: 100%;
        margin: 0;
        position: absolute;
        & > li {
          height: 100%;
          & > div {
            height: 100%;
            box-sizing: border-box;
          }
        }
      }
    }
    .navigation {
      position: absolute;
      top: 6.5rem;
      background-color: white;
      border-radius: 0;
      height: 0;
      padding-bottom: 4rem;
      background-image: url(/images/paintbrush.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 90%;
      width: 4rem;
      right: calc(49.65% - 74rem);
    }
    .navigationMenu {
      width: 2.7%;
      &.active {
        width: auto;
      }
    }
  }
}
@media (max-width: 1517px) {
  .paintable {
    #app & {
      .navigation {
        right: 0.8%;
        width: 2.75%;
        padding-bottom: 2.75%;
      }
    }
  }
}
html {
  font-size: 10px;
  height: 100%;
}
body {
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  position: initial !important;
}
.hideCursor {
  cursor: none !important;

  button,
  a,
  .wiz-button a {
    cursor: none !important;
  }
}
p,
button,
.button,
span {
  user-select: none;
}
img {
  backface-visibility: hidden;
}
#app {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: relative;
  z-index: 0;

  &:before,
  &:after {
    content: '';
    width: 200vw;
    left: -50vw;
    height: 70vh;
    background: $white-overlay;
    position: absolute;
    z-index: 0;
    transform: rotateZ(-10deg);
  }
  &:before {
    top: 5vh;
  }
  &:after {
    top: 20vh;
  }
  #positioner {
    height: 100%;
    display: flex;
    flex-direction: column;
    .modal-backgrop {
      z-index: 1;
      .modal {
        width: 40rem;
      }
    }
    &.drillsounds {
      height: 98%;
    }
    .modal-backdrop {
      z-index: 999999;
      .modal {
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;

        .modal-header {
          height: 10%;
          flex-direction: column;
          align-items: center;
          h2 {
            @extend %headings-shared;
            margin: 1rem 0;
          }
          span {
            color: black;
          }
        }
        .modal-footer {
          height: 20%;
          border-top: none;
          button {
            margin: auto;
          }
        }
      }
    }
  }
  #program-side-marker {
    @extend %headings-shared;
    position: absolute;
    right: 0;
    width: calc(5% - 1rem);
    text-align: center;
    color: white;
    font-size: 2rem;
    bottom: 18%;
    opacity: 0.5;
    &:after {
      font-size: 6rem;
      line-height: 0.6;
    }
  }
  &.fun {
    background-color: $lightblue;
    #program-side-marker {
      display: none;
    }
  }
  &.funk {
    background-color: $fun-blue;
    #program-side-marker {
      &:after {
        content: 'K';
      }
    }
    #app-bg-images {
      & > div:nth-child(1) {
        background-image: url('/images/Funk/illustrations/apple.svg');
      }
      & > div:nth-child(2) {
        background-image: url('/images/Funk/illustrations/bat.svg');
      }
      & > div:nth-child(3) {
        background-image: url('/images/Funk/illustrations/cat.svg');
      }
      & > div:nth-child(4) {
        background-image: url('/images/Funk/illustrations/fox.svg');
      }
      & > div:nth-child(5) {
        background-image: url('/images/Funk/illustrations/game.svg');
      }
      & > div:nth-child(6) {
        background-image: url('/images/Funk/illustrations/octopus.svg');
      }
      transition: all 0.5s ease-in-out;

      .in-activity & {
        opacity: 0;
      }
    }
  }
  &.fun1 {
    background-color: $fun-salmon;
    #program-side-marker {
      &:after {
        content: '1';
      }
    }
    #app-bg-images {
      & > div:nth-child(1) {
        background-image: url('/images/Fun1/illustrations/ball.svg');
      }
      & > div:nth-child(2) {
        background-image: url('/images/Fun1/illustrations/bank.svg');
      }
      & > div:nth-child(3) {
        background-image: url('/images/Fun1/illustrations/bird.svg');
      }
      & > div:nth-child(4) {
        background-image: url('/images/Fun1/illustrations/bugs.svg');
      }
      & > div:nth-child(5) {
        background-image: url('/images/Fun1/illustrations/fang.svg');
      }
      & > div:nth-child(6) {
        background-image: url('/images/Fun1/illustrations/pine.svg');
      }
    }
  }
  &.fun2 {
    background-color: $fun-green;
    #program-side-marker {
      &:after {
        content: '2';
      }
    }
    #app-bg-images {
      & > div:nth-child(1) {
        background-image: url('/images/Fun2/illustrations/wild.svg');
      }
      & > div:nth-child(2) {
        background-image: url('/images/Fun2/illustrations/jeep.svg');
      }
      & > div:nth-child(3) {
        background-image: url('/images/Fun2/illustrations/colt.svg');
      }
      & > div:nth-child(4) {
        background-image: url('/images/Fun2/illustrations/boat.svg');
      }
      & > div:nth-child(5) {
        background-image: url('/images/Fun2/illustrations/bird.svg');
      }
      & > div:nth-child(6) {
        background-image: url('/images/Fun2/illustrations/play.svg');
      }
    }
  }
  &.fun3 {
    background-color: $fun-purple;
    #program-side-marker {
      &:after {
        content: '3';
      }
    }
    #app-bg-images {
      & > div:nth-child(1) {
        background-image: url('/images/Fun3/illustrations/worm.svg');
      }
      & > div:nth-child(2) {
        background-image: url('/images/Fun3/illustrations/deuce.svg');
      }
      & > div:nth-child(3) {
        background-image: url('/images/Fun3/illustrations/catch.svg');
      }
      & > div:nth-child(4) {
        background-image: url('/images/Fun3/illustrations/wagon.svg');
      }
      & > div:nth-child(5) {
        background-image: url('/images/Fun3/illustrations/piece.svg');
      }
      & > div:nth-child(6) {
        background-image: url('/images/Fun3/illustrations/bread.svg');
      }
    }
  }
}
#app-header {
  width: 25%;
  max-width: 130rem;
  margin: 0 auto 0 0;
  box-sizing: border-box;
  display: flex;
  position: absolute;
  margin-top: -2%;
  z-index: 1;

  & > a {
    position: relative;
    padding-bottom: 1rem;
    width: 100%;
  }
}
#app-bg-images {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.2;
  & > div {
    width: 30%;
    height: 20%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    &:nth-child(1) {
      left: calc(10% - 10rem);
      top: 6rem;
      background-position: center left;
    }
    &:nth-child(2) {
      left: calc(10% - 14rem);
      top: calc(50% - 6rem);
      background-position: center left;
    }
    &:nth-child(3) {
      left: calc(10% - 10rem);
      bottom: 2rem;
      background-position: center left;
    }
    &:nth-child(4) {
      right: calc(10% - 12rem);
      top: 6rem;
      background-position: center right;
    }
    &:nth-child(5) {
      right: calc(10% - 14rem);
      top: calc(50% - 6rem);
      background-position: center right;
    }
    &:nth-child(6) {
      right: calc(10% - 10rem);
      bottom: 2rem;
      background-position: center right;
    }
  }
}
.all-fun span {
  background-color: $fun-yellow;
}
.activity-tile-in-activity {
  box-shadow: 5px 1px 10px 3px #0000003b;
  margin-bottom: 3rem;
  &:before {
    @extend %headings-shared;
    padding: 2% 6% 1% 6%;
    position: absolute;
    background-color: $fun-yellow;
    color: white;
    bottom: calc(100% - 1rem);
    right: 0;
    z-index: 1;
  }
  &:not(.all-fun) {
    &:before {
      .funk & {
        background-color: $fun-blue;
      }
      .fun1 & {
        background-color: $fun-salmon;
      }
      .fun2 & {
        background-color: $fun-green;
      }
      .fun3 & {
        background-color: $fun-purple;
      }
    }
  }
}
//footer
#app-footer {
  position: absolute;
  bottom: 0;
  top: calc(100% - 6rem);
  left: 0;
  right: 0;
  display: grid;
  width: 90%;
  max-width: 136.6rem;
  margin: 0 auto;
  grid-template-rows: 1fr 1fr;
  #footer-left-links {
    grid-row: 1 / span 2;
    align-content: center;
    justify-content: flex-start;
    display: flex;
    a {
      background-repeat: no-repeat;
      background-size: 2rem;
      background-position: center left;
      padding: 5px 2px 2px 2.5rem;
      text-align: left;
      text-transform: unset;
      font-weight: 400;
      line-height: 1;
      outline: none;
      font-size: 2rem;
      color: white;
      text-decoration: none;
      align-self: center;
      z-index: 1;
      &#feedback-button {
        background-image: url('/images/feedback.svg');
        &:after {
          content: unset;
        }
      }
    }
    #change-level {
      background-image: url('/images/change-level.svg');
      margin-left: 2rem;
    }
    #funhub-link {
      background-image: url('/images/go-back-arrow.svg');
      margin-left: 2rem;
    }
  }

  #footer-links {
    grid-row: 1;
    grid-column: 2;
    display: flex;
    margin-left: auto;
    a {
      margin-top: auto;
      text-decoration: none;
      color: white;
      font-weight: 700;
      font-size: 1.6rem;
      padding: 0.3rem 1rem;
      z-index: 1;
    }
  }
  #footer-copyrights {
    grid-row: 2;
    grid-column: 2;
    margin: 0.1rem 0 0 auto;
    color: white;
    font-size: 1.4rem;
    margin-right: 1rem;

    .version {
      display: none;
    }
  }

  #logo-wlt-link {
    grid-column: 3;
    grid-row: 1 / span 2;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-image: url(/images/Fundations_Logo_Color_simple.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
#activity-frame {
  width: 90%;
  max-width: 136.6rem;
  height: calc(100% - 8.5rem);
  margin: 1.5rem auto 6rem auto;
  background: white;
  position: relative;
  padding: 0 $activity-frame-lateral-padding $activity-frame-ventral-padding $activity-frame-lateral-padding;
  box-sizing: border-box;
  z-index: 2;
  #toggle-fullscreen {
    margin: 0 2rem 0 auto;
    height: 0;
    padding-bottom: 2%;
    width: 3%;
    background-image: url(/images/fullscreen.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    position: absolute;
    top: 0;
    right: calc(-4.5% - 2.2rem);
  }
}
.has-border {
  &:before,
  &:after {
    content: '';
    position: absolute;
  }
  &:before {
    height: 0;
    left: 0;
    top: 100%;
    width: 100%;
    background: none;
    border-top: solid $lightgray 1rem;
    border-left: solid 1rem transparent;
    border-bottom: solid 1rem transparent;
  }
  &:after {
    width: 1rem;
    top: 0;
    height: 100%;
    left: 100%;
    border-left: solid 1rem $lightgray;
    border-top: solid transparent 1rem;
  }
  &.small-border {
    &:before {
      border-top: solid $lightgray 0.5rem;
      border-left: solid 0.5rem transparent;
      border-bottom: solid 0.5rem transparent;
    }
    &:after {
      border-left: solid 0.5rem $lightgray;
      border-top: solid transparent 0.5rem;
    }
  }
}
.logo-container {
  width: 100%;
  position: relative;
  transform: rotateZ(-8deg);
  outline: 1px solid transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-background-clip: content-box;
  background-clip: content-box;
  display: flex;
  align-items: center;

  & > div {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center left;
  }
  #fun-logo-container {
    margin: 0;
    width: 20%;
    img {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-background-clip: content-box;
      background-clip: content-box;
      outline: 1px solid transparent;
      position: relative;
      z-index: 4;
    }
  }
  #ia-logo-container {
    width: 80%;
    padding: 2% 4% 0 18%;
    position: absolute;
    background-color: white;
  }
}
.wiz-mod-heading {
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin: 0 auto;
  width: 80%;
  max-width: 90rem;
  .wiz-heading {
    font-size: 1.8rem;
    margin-top: 5%;
    @media (max-height: 650px) {
      margin-top: 0;
    }
  }
  &.quick-links {
    margin-top: auto;
  }
}
#wiz-shortcuts {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0;
  grid-row-gap: 1rem;
  grid-column-gap: 1.5%;
  margin: 5rem auto 0 auto;
  @extend %inner-column;

  .wiz-shortcut {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1 0 calc(33% - 0.5rem);
    text-decoration: none;
    &#reference-posters-shortcut {
      grid-column: 3;
      img {
        width: 15rem;
        margin: -4rem auto 0.5rem auto;
      }
    }
    &#cursive-shortcut {
      grid-column: 2;
      img {
        width: 15rem;
        margin: -2.75rem auto 0.5rem auto;
        box-shadow: 3px 3px 10px -3px black;
        transform: rotate(-2deg);
      }
    }
  }
}
.wiz-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0;
  margin: 1rem auto auto auto;
  grid-row-gap: 1rem;
  grid-column-gap: 1.5%;
  @extend %inner-column;
  li {
    list-style-type: none;
    flex-grow: 0;
    flex-basis: calc(33.3% - 6rem);
    .wiz-button {
      @extend %button;

      .funk & {
        background-color: $fun-blue;
      }
      .fun1 & {
        background-color: $fun-salmon;
      }
      .fun2 & {
        background-color: $fun-green;
      }
      .fun3 & {
        background-color: $fun-purple;
      }
    }
  }
}

#card-positioner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.star {
  top: 3px;
  left: -2px;
  position: absolute;
  display: block;
  width: 0px;
  height: 0px;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  transform: rotate(35deg);
  &.new-star {
    border-bottom: 7px solid #d55cea;
    &:before {
      border-bottom: 8px solid #d55cea;
    }
    &:after {
      border-bottom: 7px solid #d55cea;
    }
  }
  &.selected-star {
    border-bottom: 7px solid #ffa500;
    &:before {
      border-bottom: 8px solid #ffa500;
    }
    &:after {
      border-bottom: 7px solid #ffa500;
    }
  }

  &:before {
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
    height: 0;
    width: 0;
    top: -6.5px;
    left: -7.5px;
    display: block;
    content: '';
    transform: rotate(-35deg);
  }
  &:after {
    position: absolute;
    display: block;
    color: #f48372;
    top: 0.3px;
    left: -10.5px;
    width: 0px;
    height: 0px;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    transform: rotate(-70deg);
    content: '';
  }
}
.explainer-label {
  span {
    @extend %headings-shared;
    font-size: 1.6rem;
    margin: auto 0 auto 0.5rem;
    background-image: radial-gradient(circle, #fffca7, transparent);
    white-space: nowrap;
  }
}
.slideOutMessage {
  box-shadow: 0px 0px 37px -10px black;
  z-index: 2;
  opacity: 0;
  transition: all 1s ease-in-out;
  background: #fdf1d4;
  padding: 10px;
  font-weight: 700;
  &.shown {
    opacity: 1;
    transform: translateY(0) translatex(0) !important;
  }
  &:before {
    content: '';
    background-color: whitesmoke;
    background-image: url(/images/Fun1/illustrations/whistle.svg);
    background-size: 30px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    right: 100%;
    top: 0;
    width: 40px;
    bottom: 0;
    margin: 0 auto;
  }
}
@media (min-width: 1518px) {
  #app {
    #program-side-marker {
      font-size: 5rem;
      width: calc((100% - 136.6rem) / 2);
      &:after {
        font-size: 15rem;
      }
    }
  }
}

@media (max-height: 730px), (max-width: 750px) {
  .wiz-buttons,
  .wiz-mod-heading {
    width: 100%;
  }
}

@media (max-width: 910px) {
  #app-footer {
    #footer-left-links {
      align-content: flex-start;
      justify-content: center;
      flex-direction: column;
      a {
        align-self: flex-start;
        font-size: 1.75rem;
        &#change-level {
          margin-left: 0;
        }
      }
      // #feedback-button {
      //   font-size: 1.2rem;
      // }
    }
    #footer-links a {
      font-size: 1.2rem;
      padding: 0.5rem;
    }
  }
}
@media (max-width: 900px) {
  #activity-frame {
    padding: 0 $small-frame-lateral-padding $small-frame-lateral-padding $small-frame-lateral-padding;
  }
}

#increase-size-overlay {
  display: none;
  z-index: 1;
}
@media (max-width: 900px), (max-height: 550px) {
  #app {
    #positioner {
      // display: none;
    }
  }
  #increase-size-overlay {
    padding: $small-frame-lateral-padding;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;

    #increase-heading {
      font-size: 3rem;
      font-weight: 700;

      #increase-word {
        font-size: 4.6rem;
        color: $red-accent;
      }
    }
    #increase-reason {
      font-size: 2.2rem;
      line-height: 1.4;
    }
    & > * {
      margin: 2rem auto;
    }
  }
}
@media (max-height: 730px) {
  #wiz-shortcuts {
    margin-top: 4rem;
    margin-bottom: -8rem;
    width: 100%;
  }
  .pickup {
    & ~ .wiz-buttons {
      grid-row-gap: 0.5rem;
    }
  }
}
@media (max-height: 650px) {
  .wiz-mod-heading .wiz-heading {
    margin-top: 5%;
  }
}
</style>
