import { render, staticRenderFns } from "./StandardSoundCards.vue?vue&type=template&id=ca990452&scoped=true&"
import script from "./StandardSoundCards.vue?vue&type=script&lang=js&"
export * from "./StandardSoundCards.vue?vue&type=script&lang=js&"
import style0 from "./StandardSoundCards.vue?vue&type=style&index=0&id=ca990452&lang=scss&scoped=true&"
import style1 from "./StandardSoundCards.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca990452",
  null
  
)

export default component.exports