<template>
  <div id="positioner">
    <div v-if="canResume" class="pickup">
      <img src="/images/Fun2/illustrations/bait.svg" alt>
      <p>You last left off on Day {{ lastLesson.day }} of Unit {{ lastLesson.unit }}, Week {{ lastLesson.week }}.</p>
      <p><em>Would you like to resume the last lesson, or begin the next lesson?</em></p>
      <button @click="resume()">Resume</button>
      <button v-if="nextLesson" @click="next()">Next</button>
      <p>(Day {{ nextLesson.day }} of Unit {{ nextLesson.unit }}, Week {{ nextLesson.week }})</p>
    </div>

    <div id="wiz-shortcuts">
      <div
        v-if="programId === 'fun3'"
        class="button wiz-shortcut"
        id="cursive-shortcut"
        @click="selectActivity('cursive')"
      >
        <img alt="cursive letter formation" src="/images/poster-tiles/letter-formation.svg">
        <span>Cursive Letter Formation</span>
      </div>

      <div
        @click="selectActivity('referenceposters')"
        class="button wiz-shortcut"
        id="reference-posters-shortcut"
        v-if="programId !== 'funk' && programId !== 'fun1'"
        >
        <img alt="reference posterrs" src="/images/poster-tiles/posters.svg">
        <span>Reference Posters</span>
      </div>
    </div>

    <template v-if="programId !== 'funk' && programId !== 'fun1'">
      <div class="wiz-mod-heading">
        <h1 class="wiz-heading">Select a Unit</h1>
      </div>

      <ul class="units-container wiz-buttons">
        <li v-for="unit in allUnits(`${programId}Data`)" :key="unit">
          <div class="unit wiz-button" v-bind:aria-label="unitName(unit)" @click="unitClicked(unit)">{{ unitName(unit) }}</div>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import router from '@/router';
import mixpanel from 'mixpanel-browser';

export default {
  name: 'ChooseUnit',
  // data() {
  //   const { programId } = this.$route.params;
  //   return { programId };
  // },
  props: {
    programId: { type: String },
  },
  computed: {
    ...mapGetters('dataStore', ['allUnits', 'weeksForUnit']),
    ...mapGetters('appState', ['lastLesson', 'nextLesson']),
    canResume() {
      return process.env.VUE_APP_HIDE_WEEK_BUTTONS === 'false' && this.lastLesson && this.lastLesson.unit !== '' && this.lastLesson.week !== '' && this.lastLesson.day !== '';
    },

  },
  methods: {
    selectActivity(activity) {
      mixpanel.track('Activity Selected', { Unit: this.unitId, Week: this.weekId, Day: this.dayId, Activity: activity });
      router.push({ name: activity, params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId, dayId: this.dayId } });
    },
    unitClicked(unit) {
      mixpanel.track('Unit Selected', { Unit: unit });
      router.push({ name: 'chooseweek', params: { programId: this.programId, unitId: unit.toString() } });
    },
    unitName(unit) {
      return unit !== 'Bonus' ? `Unit ${unit}` : 'Bonus Unit';
    },
    resume() {
      router.push({
        name: 'chooseactivity',
        params: {
          programId: this.lastLesson.program,
          unitId: this.lastLesson.unit,
          weekId: this.lastLesson.week,
          dayId: this.lastLesson.day,
        },
      });
    },
    next() {
      router.push({
        name: 'chooseactivity',
        params: {
          programId: this.nextLesson.program,
          unitId: this.nextLesson.unit,
          weekId: this.nextLesson.week,
          dayId: this.nextLesson.day,
        },
      });
    },
  },
};
</script>
<style lang="scss">
  @media (max-height: 730px) {

    #wiz-shortcuts[data-v-d7e10716] {
      margin-top: 4rem;
      margin-bottom: -8rem;
    }
    .pickup{
      &~.wiz-buttons{
        grid-row-gap: .5rem;
      }
    }
  }
</style>
<style lang="scss" scoped>

.no-activity-header{
  margin-top: 10%;
}
.pickup {
  @extend %inner-column;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: minmax(12rem, 20%) auto;
  box-sizing: border-box;
  margin: auto auto 0 auto;
  background-color: $whitesmoke;
  padding: 2% 3%;
  img {
    grid-column: 1;
    grid-row: 1 / span 4;
    align-self: flex-end;
    max-width: 55%;
    margin: -20px auto 0px auto;
  }
  p {
    text-align: center;
    font-size: 1.8rem;
    line-height: 1;
    margin: auto;
    &:nth-child(2) {
      grid-row: 1;
      grid-column: 2 / span 2;
    }
    &:nth-child(3) {
      grid-row: 2;
      grid-column: 2 / span 2;
    }
    &:nth-child(6) {
      grid-row: 4;
      grid-column: 2 / span 2;
      color: gray;
    }
  }
  button {
    margin: 0 auto;
    align-self: center;
    &:nth-of-type(1) {
      grid-row: 3;
      grid-column: 2;
      margin-right: 0.5rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    &:nth-of-type(2) {
      grid-row: 3;
      grid-column: 3;
      margin-left: 0.5rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  &+#wiz-shortcuts {
    margin-top: 5rem;
  }
}
#wiz-shortcuts{
  margin-top: auto;
  margin-bottom: -7rem;
}

@media (max-height: 730px), (max-width: 750px){
  .pickup,
  .activity-tiles.drillsounds-menu{
   width: 100%;
  }
}

@media (max-height: 600px){
  .pickup{
    padding: 1% 3%;
  }
}
@media (max-width: 530px){
  #wiz-shortcuts{
  margin-bottom: -3.5rem;
}
  .pickup{
    grid-template-columns: auto;
    img{
      grid-column: 1 / span 3;
      grid-row: 1;
      max-width: 25%;
    }
    p {
      &:nth-child(2) {
        grid-row: 2;
        grid-column: 1 / span 2;
      }
      &:nth-child(3) {
        grid-row: 3;
        grid-column: 1 / span 2;
      }
      &:nth-child(6) {
        grid-row: 5;
        grid-column: 1 / span 2;
      }
    }
    button {
      &:nth-of-type(1) {
        grid-row: 4;
        grid-column: 1;
        margin-right: 0.5rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      &:nth-of-type(2) {
        grid-row: 4;
        grid-column: 2;
        margin-left: 0.5rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
