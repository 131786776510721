import apiService from '@/api/apiService';

export default {
  namespaced: true,
  state: {
    customClasses: [],
    unitWeekXref: [
      // dummy data. please replace
      {
        program: 'funk',
        xRef: [
          { UnitWeek: '1-1', week: 1 },
          { UWD: '1-1-1', day: 1 },
        ],
      },
      // dummy data. please replace
      {
        program: 'fun1',
        xRef: [
          { UnitWeek: '1-1', week: 1 },
          { UWD: '1-1-1', day: 1 },
        ],
      },
      {
        program: 'fun2',
        xRef: [
          { UnitWeek: '1-1', week: 1 },
          { UnitWeek: '1-2', week: 2 },
          { UnitWeek: '2-1', week: 3 },
          { UnitWeek: '2-2', week: 4 },
          { UnitWeek: '3-1', week: 5 },
          { UnitWeek: '4-1', week: 6 },
          { UnitWeek: '4-2', week: 7 },
          { UnitWeek: '5-1', week: 8 },
          { UnitWeek: '5-2', week: 9 },
          { UnitWeek: '6-1', week: 10 },
          { UnitWeek: '6-2', week: 11 },
          { UnitWeek: '7-1', week: 12 },
          { UnitWeek: '7-2', week: 13 },
          { UnitWeek: '7-3', week: 14 },
          { UnitWeek: '8-1', week: 15 },
          { UnitWeek: '9-1', week: 16 },
          { UnitWeek: '9-2', week: 17 },
          { UnitWeek: '10-1', week: 18 },
          { UnitWeek: '10-2', week: 19 },
          { UnitWeek: '11-1', week: 20 },
          { UnitWeek: '11-2', week: 21 },
          { UnitWeek: '12-1', week: 22 },
          { UnitWeek: '13-1', week: 23 },
          { UnitWeek: '13-2', week: 24 },
          { UnitWeek: '14-1', week: 25 },
          { UnitWeek: '14-2', week: 26 },
          { UnitWeek: '15-1', week: 27 },
          { UnitWeek: '15-2', week: 28 },
          { UnitWeek: '16-1', week: 29 },
          { UnitWeek: '17-1', week: 30 },
          { UnitWeek: '17-2', week: 31 },

          { UWD: '1-1-1', day: 1 },
          { UWD: '1-1-2', day: 2 },
          { UWD: '1-1-3', day: 3 },
          { UWD: '1-1-4', day: 4 },
          { UWD: '1-1-5', day: 5 },
          { UWD: '1-2-1', day: 6 },
          { UWD: '1-2-2', day: 7 },
          { UWD: '1-2-3', day: 8 },
          { UWD: '1-2-4', day: 9 },
          { UWD: '1-2-5', day: 10 },

          { UWD: '2-1-1', day: 11 },
          { UWD: '2-1-2', day: 12 },
          { UWD: '2-1-3', day: 13 },
          { UWD: '2-1-4', day: 14 },
          { UWD: '2-1-5', day: 15 },
          { UWD: '2-2-1', day: 16 },
          { UWD: '2-2-2', day: 17 },
          { UWD: '2-2-3', day: 18 },
          { UWD: '2-2-4', day: 19 },
          { UWD: '2-2-5', day: 20 },

          { UWD: '3-1-1', day: 21 },
          { UWD: '3-1-2', day: 22 },
          { UWD: '3-1-3', day: 23 },
          { UWD: '3-1-4', day: 24 },
          { UWD: '3-1-5', day: 25 },

          { UWD: '4-1-1', day: 26 },
          { UWD: '4-1-2', day: 27 },
          { UWD: '4-1-3', day: 28 },
          { UWD: '4-1-4', day: 29 },
          { UWD: '4-1-5', day: 30 },
          { UWD: '4-2-1', day: 31 },
          { UWD: '4-2-2', day: 32 },
          { UWD: '4-2-3', day: 33 },
          { UWD: '4-2-4', day: 34 },
          { UWD: '4-2-5', day: 35 },

          { UWD: '5-1-1', day: 36 },
          { UWD: '5-1-2', day: 37 },
          { UWD: '5-1-3', day: 38 },
          { UWD: '5-1-4', day: 39 },
          { UWD: '5-1-5', day: 40 },
          { UWD: '5-2-1', day: 41 },
          { UWD: '5-2-2', day: 42 },
          { UWD: '5-2-3', day: 43 },
          { UWD: '5-2-4', day: 44 },
          { UWD: '5-2-5', day: 45 },

          { UWD: '6-1-1', day: 46 },
          { UWD: '6-1-2', day: 47 },
          { UWD: '6-1-3', day: 48 },
          { UWD: '6-1-4', day: 49 },
          { UWD: '6-1-5', day: 50 },
          { UWD: '6-2-1', day: 51 },
          { UWD: '6-2-2', day: 52 },
          { UWD: '6-2-3', day: 53 },
          { UWD: '6-2-4', day: 54 },
          { UWD: '6-2-5', day: 55 },

          { UWD: '7-1-1', day: 56 },
          { UWD: '7-1-2', day: 57 },
          { UWD: '7-1-3', day: 58 },
          { UWD: '7-1-4', day: 59 },
          { UWD: '7-1-5', day: 60 },
          { UWD: '7-2-1', day: 61 },
          { UWD: '7-2-2', day: 62 },
          { UWD: '7-2-3', day: 63 },
          { UWD: '7-2-4', day: 64 },
          { UWD: '7-2-5', day: 65 },
          { UWD: '7-3-1', day: 66 },
          { UWD: '7-3-2', day: 67 },
          { UWD: '7-3-3', day: 68 },
          { UWD: '7-3-4', day: 69 },
          { UWD: '7-3-5', day: 70 },

          { UWD: '8-1-1', day: 71 },
          { UWD: '8-1-2', day: 72 },
          { UWD: '8-1-3', day: 73 },
          { UWD: '8-1-4', day: 74 },
          { UWD: '8-1-5', day: 75 },

          { UWD: '9-1-1', day: 76 },
          { UWD: '9-1-2', day: 77 },
          { UWD: '9-1-3', day: 78 },
          { UWD: '9-1-4', day: 79 },
          { UWD: '9-1-5', day: 80 },
          { UWD: '9-2-1', day: 81 },
          { UWD: '9-2-2', day: 82 },
          { UWD: '9-2-3', day: 83 },
          { UWD: '9-2-4', day: 84 },
          { UWD: '9-2-5', day: 85 },

          { UWD: '10-1-1', day: 86 },
          { UWD: '10-1-2', day: 87 },
          { UWD: '10-1-3', day: 88 },
          { UWD: '10-1-4', day: 89 },
          { UWD: '10-1-5', day: 90 },
          { UWD: '10-2-1', day: 91 },
          { UWD: '10-2-2', day: 92 },
          { UWD: '10-2-3', day: 93 },
          { UWD: '10-2-4', day: 94 },
          { UWD: '10-2-5', day: 95 },

          { UWD: '11-1-1', day: 96 },
          { UWD: '11-1-2', day: 97 },
          { UWD: '11-1-3', day: 98 },
          { UWD: '11-1-4', day: 99 },
          { UWD: '11-1-5', day: 100 },
          { UWD: '11-2-1', day: 101 },
          { UWD: '11-2-2', day: 102 },
          { UWD: '11-2-3', day: 103 },
          { UWD: '11-2-4', day: 104 },
          { UWD: '11-2-5', day: 105 },

          { UWD: '12-1-1', day: 106 },
          { UWD: '12-1-2', day: 107 },
          { UWD: '12-1-3', day: 108 },
          { UWD: '12-1-4', day: 109 },
          { UWD: '12-1-5', day: 110 },

          { UWD: '13-1-1', day: 111 },
          { UWD: '13-1-2', day: 112 },
          { UWD: '13-1-3', day: 113 },
          { UWD: '13-1-4', day: 114 },
          { UWD: '13-1-5', day: 115 },
          { UWD: '13-2-1', day: 116 },
          { UWD: '13-2-2', day: 117 },
          { UWD: '13-2-3', day: 118 },
          { UWD: '13-2-4', day: 119 },
          { UWD: '13-2-5', day: 120 },

          { UWD: '14-1-1', day: 121 },
          { UWD: '14-1-2', day: 122 },
          { UWD: '14-1-3', day: 123 },
          { UWD: '14-1-4', day: 124 },
          { UWD: '14-1-5', day: 125 },
          { UWD: '14-2-1', day: 126 },
          { UWD: '14-2-2', day: 127 },
          { UWD: '14-2-3', day: 128 },
          { UWD: '14-2-4', day: 129 },
          { UWD: '14-2-5', day: 130 },

          { UWD: '15-1-1', day: 131 },
          { UWD: '15-1-2', day: 132 },
          { UWD: '15-1-3', day: 133 },
          { UWD: '15-1-4', day: 134 },
          { UWD: '15-1-5', day: 135 },
          { UWD: '15-2-1', day: 136 },
          { UWD: '15-2-2', day: 137 },
          { UWD: '15-2-3', day: 138 },
          { UWD: '15-2-4', day: 139 },
          { UWD: '15-2-5', day: 140 },

          { UWD: '16-1-1', day: 141 },
          { UWD: '16-1-2', day: 142 },
          { UWD: '16-1-3', day: 143 },
          { UWD: '16-1-4', day: 144 },
          { UWD: '16-1-5', day: 145 },

          { UWD: '17-1-1', day: 146 },
          { UWD: '17-1-2', day: 147 },
          { UWD: '17-1-3', day: 148 },
          { UWD: '17-1-4', day: 149 },
          { UWD: '17-1-5', day: 150 },
          { UWD: '17-2-1', day: 151 },
          { UWD: '17-2-2', day: 152 },
          { UWD: '17-2-3', day: 153 },
          { UWD: '17-2-4', day: 154 },
          { UWD: '17-2-5', day: 155 },
        ],
      },
      {
        program: 'fun3',
        xRef: [
          // { UnitWeek: '1-0', week: 0 },
          { UnitWeek: '1-1', week: 1 },
          { UnitWeek: '1-2', week: 2 },
          { UnitWeek: '2-1', week: 3 },
          { UnitWeek: '2-2', week: 4 },
          { UnitWeek: '2-3', week: 5 },
          { UnitWeek: '3-1', week: 6 },
          { UnitWeek: '4-1', week: 7 },
          { UnitWeek: '4-2', week: 8 },
          { UnitWeek: '5-1', week: 9 },
          { UnitWeek: '5-2', week: 10 },
          { UnitWeek: '6-1', week: 11 },
          { UnitWeek: '6-2', week: 12 },
          { UnitWeek: '6-3', week: 13 },
          { UnitWeek: 'Bonus-1', week: 14 },
          { UnitWeek: 'Bonus-2', week: 15 },
          { UnitWeek: '7-1', week: 16 },
          { UnitWeek: '7-2', week: 17 },
          { UnitWeek: '8-1', week: 18 },
          { UnitWeek: '8-2', week: 19 },
          { UnitWeek: '8-3', week: 20 },
          { UnitWeek: '9-1', week: 21 },
          { UnitWeek: '9-2', week: 22 },
          { UnitWeek: '9-3', week: 23 },
          { UnitWeek: '10-1', week: 24 },
          { UnitWeek: '10-2', week: 25 },
          { UnitWeek: '10-3', week: 26 },
          { UnitWeek: '11-1', week: 27 },
          { UnitWeek: '11-2', week: 28 },
          { UnitWeek: '12-1', week: 29 },
          { UnitWeek: '12-2', week: 30 },
          { UnitWeek: '13-1', week: 31 },
          { UnitWeek: '13-2', week: 32 },
          { UnitWeek: '14-1', week: 33 },
          { UnitWeek: '14-2', week: 34 },

          { UWD: '1-1-1', day: 1 },
          { UWD: '1-1-2', day: 2 },
          { UWD: '1-1-3', day: 3 },
          { UWD: '1-1-4', day: 4 },
          { UWD: '1-1-5', day: 5 },
          { UWD: '1-2-1', day: 6 },
          { UWD: '1-2-2', day: 7 },
          { UWD: '1-2-3', day: 8 },
          { UWD: '1-2-4', day: 9 },
          { UWD: '1-2-5', day: 10 },

          { UWD: '2-1-1', day: 11 },
          { UWD: '2-1-2', day: 12 },
          { UWD: '2-1-3', day: 13 },
          { UWD: '2-1-4', day: 14 },
          { UWD: '2-1-5', day: 15 },
          { UWD: '2-2-1', day: 16 },
          { UWD: '2-2-2', day: 17 },
          { UWD: '2-2-3', day: 18 },
          { UWD: '2-2-4', day: 19 },
          { UWD: '2-2-5', day: 20 },
          { UWD: '2-3-1', day: 21 },
          { UWD: '2-3-2', day: 22 },
          { UWD: '2-3-3', day: 23 },
          { UWD: '2-3-4', day: 24 },
          { UWD: '2-3-5', day: 25 },

          { UWD: '3-1-1', day: 26 },
          { UWD: '3-1-2', day: 27 },
          { UWD: '3-1-3', day: 28 },
          { UWD: '3-1-4', day: 29 },
          { UWD: '3-1-5', day: 30 },

          { UWD: '4-1-1', day: 31 },
          { UWD: '4-1-2', day: 32 },
          { UWD: '4-1-3', day: 33 },
          { UWD: '4-1-4', day: 34 },
          { UWD: '4-1-5', day: 35 },
          { UWD: '4-2-1', day: 36 },
          { UWD: '4-2-2', day: 37 },
          { UWD: '4-2-3', day: 38 },
          { UWD: '4-2-4', day: 39 },
          { UWD: '4-2-5', day: 40 },

          { UWD: '5-1-1', day: 41 },
          { UWD: '5-1-2', day: 42 },
          { UWD: '5-1-3', day: 43 },
          { UWD: '5-1-4', day: 44 },
          { UWD: '5-1-5', day: 45 },
          { UWD: '5-2-1', day: 46 },
          { UWD: '5-2-2', day: 47 },
          { UWD: '5-2-3', day: 48 },
          { UWD: '5-2-4', day: 49 },
          { UWD: '5-2-5', day: 50 },

          { UWD: '6-1-1', day: 51 },
          { UWD: '6-1-2', day: 52 },
          { UWD: '6-1-3', day: 53 },
          { UWD: '6-1-4', day: 54 },
          { UWD: '6-1-5', day: 55 },
          { UWD: '6-2-1', day: 56 },
          { UWD: '6-2-2', day: 57 },
          { UWD: '6-2-3', day: 58 },
          { UWD: '6-2-4', day: 59 },
          { UWD: '6-2-5', day: 60 },
          { UWD: '6-3-1', day: 61 },
          { UWD: '6-3-2', day: 62 },
          { UWD: '6-3-3', day: 63 },
          { UWD: '6-3-4', day: 64 },
          { UWD: '6-3-5', day: 65 },

          { UWD: 'Bonus-1-1', day: 66 },
          { UWD: 'Bonus-1-2', day: 67 },
          { UWD: 'Bonus-1-3', day: 68 },
          { UWD: 'Bonus-1-4', day: 69 },
          { UWD: 'Bonus-1-5', day: 70 },
          { UWD: 'Bonus-2-1', day: 71 },
          { UWD: 'Bonus-2-2', day: 72 },
          { UWD: 'Bonus-2-3', day: 73 },
          { UWD: 'Bonus-2-4', day: 74 },
          { UWD: 'Bonus-2-5', day: 75 },

          { UWD: '7-1-1', day: 76 },
          { UWD: '7-1-2', day: 77 },
          { UWD: '7-1-3', day: 78 },
          { UWD: '7-1-4', day: 79 },
          { UWD: '7-1-5', day: 80 },
          { UWD: '7-2-1', day: 81 },
          { UWD: '7-2-2', day: 82 },
          { UWD: '7-2-3', day: 83 },
          { UWD: '7-2-4', day: 84 },
          { UWD: '7-2-5', day: 85 },

          { UWD: '8-1-1', day: 86 },
          { UWD: '8-1-2', day: 87 },
          { UWD: '8-1-3', day: 88 },
          { UWD: '8-1-4', day: 89 },
          { UWD: '8-1-5', day: 90 },
          { UWD: '8-2-1', day: 91 },
          { UWD: '8-2-2', day: 92 },
          { UWD: '8-2-3', day: 93 },
          { UWD: '8-2-4', day: 94 },
          { UWD: '8-2-5', day: 95 },
          { UWD: '8-3-1', day: 96 },
          { UWD: '8-3-2', day: 97 },
          { UWD: '8-3-3', day: 98 },
          { UWD: '8-3-4', day: 99 },
          { UWD: '8-3-5', day: 100 },

          { UWD: '9-1-1', day: 101 },
          { UWD: '9-1-2', day: 102 },
          { UWD: '9-1-3', day: 103 },
          { UWD: '9-1-4', day: 104 },
          { UWD: '9-1-5', day: 105 },
          { UWD: '9-2-1', day: 106 },
          { UWD: '9-2-2', day: 107 },
          { UWD: '9-2-3', day: 108 },
          { UWD: '9-2-4', day: 109 },
          { UWD: '9-2-5', day: 110 },
          { UWD: '9-3-1', day: 111 },
          { UWD: '9-3-2', day: 112 },
          { UWD: '9-3-3', day: 113 },
          { UWD: '9-3-4', day: 114 },
          { UWD: '9-3-5', day: 115 },

          { UWD: '10-1-1', day: 116 },
          { UWD: '10-1-2', day: 117 },
          { UWD: '10-1-3', day: 118 },
          { UWD: '10-1-4', day: 119 },
          { UWD: '10-1-5', day: 120 },
          { UWD: '10-2-1', day: 121 },
          { UWD: '10-2-2', day: 122 },
          { UWD: '10-2-3', day: 123 },
          { UWD: '10-2-4', day: 124 },
          { UWD: '10-2-5', day: 125 },
          { UWD: '10-3-1', day: 126 },
          { UWD: '10-3-2', day: 127 },
          { UWD: '10-3-3', day: 128 },
          { UWD: '10-3-4', day: 129 },
          { UWD: '10-3-5', day: 130 },

          { UWD: '11-1-1', day: 131 },
          { UWD: '11-1-2', day: 132 },
          { UWD: '11-1-3', day: 133 },
          { UWD: '11-1-4', day: 134 },
          { UWD: '11-1-5', day: 125 },
          { UWD: '11-2-1', day: 136 },
          { UWD: '11-2-2', day: 137 },
          { UWD: '11-2-3', day: 138 },
          { UWD: '11-2-4', day: 139 },
          { UWD: '11-2-5', day: 140 },

          { UWD: '12-1-1', day: 141 },
          { UWD: '12-1-2', day: 142 },
          { UWD: '12-1-3', day: 143 },
          { UWD: '12-1-4', day: 144 },
          { UWD: '12-1-5', day: 145 },
          { UWD: '12-2-1', day: 146 },
          { UWD: '12-2-2', day: 147 },
          { UWD: '12-2-3', day: 148 },
          { UWD: '12-2-4', day: 149 },
          { UWD: '12-2-5', day: 150 },

          { UWD: '13-1-1', day: 151 },
          { UWD: '13-1-2', day: 152 },
          { UWD: '13-1-3', day: 153 },
          { UWD: '13-1-4', day: 154 },
          { UWD: '13-1-5', day: 155 },
          { UWD: '13-2-1', day: 156 },
          { UWD: '13-2-2', day: 157 },
          { UWD: '13-2-3', day: 158 },
          { UWD: '13-2-4', day: 159 },
          { UWD: '13-2-5', day: 160 },

          { UWD: '14-1-1', day: 161 },
          { UWD: '14-1-2', day: 162 },
          { UWD: '14-1-3', day: 163 },
          { UWD: '14-1-4', day: 164 },
          { UWD: '14-1-5', day: 165 },
          { UWD: '14-2-1', day: 166 },
          { UWD: '14-2-2', day: 167 },
          { UWD: '14-2-3', day: 168 },
          { UWD: '14-2-4', day: 169 },
          { UWD: '14-2-5', day: 170 },
        ],
      },
    ],

    // dummy data. please replace
    funkData: {
      units: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17'],
      weeks: [
        { unit: '1', weeks: [1, 2] },
        { unit: '2', weeks: [1, 2] },
        { unit: '3', weeks: [1] },
        { unit: '4', weeks: [1, 2] },
        { unit: '5', weeks: [1, 2] },
        { unit: '6', weeks: [1, 2] },
        { unit: '7', weeks: [1, 2, 3] },
        { unit: '8', weeks: [1] },
        { unit: '9', weeks: [1, 2] },
        { unit: '10', weeks: [1, 2] },
        { unit: '11', weeks: [1, 2] },
        { unit: '12', weeks: [1] },
        { unit: '13', weeks: [1, 2] },
        { unit: '14', weeks: [1, 2] },
        { unit: '15', weeks: [1, 2] },
        { unit: '16', weeks: [1] },
        { unit: '17', weeks: [1, 2] },
      ],
    },
    // dummy data. please replace
    fun1Data: {
      units: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17'],
      weeks: [
        { unit: '1', weeks: [1, 2] },
        { unit: '2', weeks: [1, 2] },
        { unit: '3', weeks: [1] },
        { unit: '4', weeks: [1, 2] },
        { unit: '5', weeks: [1, 2] },
        { unit: '6', weeks: [1, 2] },
        { unit: '7', weeks: [1, 2, 3] },
        { unit: '8', weeks: [1] },
        { unit: '9', weeks: [1, 2] },
        { unit: '10', weeks: [1, 2] },
        { unit: '11', weeks: [1, 2] },
        { unit: '12', weeks: [1] },
        { unit: '13', weeks: [1, 2] },
        { unit: '14', weeks: [1, 2] },
        { unit: '15', weeks: [1, 2] },
        { unit: '16', weeks: [1] },
        { unit: '17', weeks: [1, 2] },
      ],
    },
    fun2Data: {
      units: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17'],
      weeks: [
        { unit: '1', weeks: [1, 2] },
        { unit: '2', weeks: [1, 2] },
        { unit: '3', weeks: [1] },
        { unit: '4', weeks: [1, 2] },
        { unit: '5', weeks: [1, 2] },
        { unit: '6', weeks: [1, 2] },
        { unit: '7', weeks: [1, 2, 3] },
        { unit: '8', weeks: [1] },
        { unit: '9', weeks: [1, 2] },
        { unit: '10', weeks: [1, 2] },
        { unit: '11', weeks: [1, 2] },
        { unit: '12', weeks: [1] },
        { unit: '13', weeks: [1, 2] },
        { unit: '14', weeks: [1, 2] },
        { unit: '15', weeks: [1, 2] },
        { unit: '16', weeks: [1] },
        { unit: '17', weeks: [1, 2] },
      ],
    },

    fun3Data: {
      units: ['1', '2', '3', '4', '5', '6', 'Bonus', '7', '8', '9', '10', '11', '12', '13', '14'],
      weeks: [
        { unit: '1', weeks: [1, 2] },
        { unit: '2', weeks: [1, 2, 3] },
        { unit: '3', weeks: [1] },
        { unit: '4', weeks: [1, 2] },
        { unit: '5', weeks: [1, 2] },
        { unit: '6', weeks: [1, 2, 3] },
        { unit: 'Bonus', weeks: [1, 2] },
        { unit: '7', weeks: [1, 2] },
        { unit: '8', weeks: [1, 2, 3] },
        { unit: '9', weeks: [1, 2, 3] },
        { unit: '10', weeks: [1, 2, 3] },
        { unit: '11', weeks: [1, 2] },
        { unit: '12', weeks: [1, 2] },
        { unit: '13', weeks: [1, 2] },
        { unit: '14', weeks: [1, 2] },
      ],
    },
  },

  mutations: {
    SET_CUSTOMCLASSES: (state, newClasses) => {
      state.customClasses = newClasses;
    },
  },

  actions: {
    loadCustomClasses: async (context) => {
      const data = apiService.callApi('api/Data/GetCustomClasses');
      context.commit('SET_CUSTOMCLASSES', data);
    },
  },

  getters: {
    allUnits: (state) => (program) => state[program].units,
    weeksForUnit: (state) => (program, unit) => state[program].weeks.find((f) => f.unit === unit).weeks,
    weekNumber: (state) => (programId, unitId, weekId) => {
      if (!weekId || !unitId) {
        return 52;
      }
      if (weekId === '0') {
        const data = state[`${programId}Data`].weeks;
        const { weeks } = data.find((f) => f.unit === unitId);
        weekId = weeks[weeks.length - 1];
      }
      const weekNumber = state.unitWeekXref.find((c) => c.program === programId).xRef.find((c) => c.UnitWeek === `${unitId}-${weekId}`).week;
      return weekNumber;
    },

    dayNumber: (state) => (programId, unitId, weekId, dayId, specialForBuildWords) => {
      if (unitId === undefined || weekId === undefined || dayId === undefined) {
        return 365;
      }
      if (weekId === '0' || dayId === '0') {
        if (programId === 'funk') {
          programId = 'fun2';
          weekId = 1;
          dayId = 1;
          unitId = 1;
        } else if (programId === 'fun1') {
          programId = 'fun2';
          weekId = 2;
          dayId = 2;
          unitId = 4;
        } else {
          const data = state[`${programId}Data`].weeks;
          const { weeks } = data.find((f) => f.unit === unitId);
          if (!specialForBuildWords) {
            weekId = weeks[weeks.length - 1];
            dayId = 5;
          } else {
            // if new unit level build words, default to first week/day of unit
            weekId = 1;
            dayId = 1;
          }
        }
      }
      const dayNumber = state.unitWeekXref.find((c) => c.program === programId).xRef.find((c) => c.UWD === `${unitId}-${weekId}-${dayId}`).day;
      return dayNumber;
    },

    unitAndWeek: (state) => (programId, weekNumber) => {
      const unitAndWeek = state.unitWeekXref.find((c) => c.program === programId).xRef.find((c) => c.week === weekNumber).UnitWeek;
      const [unit, week] = unitAndWeek.split('-');
      return `Unit ${unit} Week ${week}`;
    },
    unitWeek: (state) => (programId, weekNumber) => {
      const unitWeek = state.unitWeekXref.find((c) => c.program === programId).xRef.find((c) => c.week === weekNumber);
      if (unitWeek) {
        const [unit, week] = unitWeek.UnitWeek.split('-');
        return { unit, week };
      }
      return null;
    },
  },
};
